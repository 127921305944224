export const renderCurrency = (amount = 0, currency = 'QAR') => {
    // const value = new Intl.NumberFormat('en-AE', {
    //     style: 'currency',
    //     currency: currency,
    // });
    // return value.format(amount);
    return `${currency} ${amount}`;
    // return
};

export const getFormattedCartItems = (cartItems = []) => {
    const formattedItems = cartItems.map((cartItem) => {
        const { id, itemCount, addons, instruction } = cartItem;

        const formattedItem = {
            menu_item_id: id,
            quantity: itemCount,
            description: instruction,
            addons: [],
        };

        const formatSubAddons = (addons) => {
            addons.forEach((addon) => {
                if (addon.quantity > 0) {
                    formattedItem.addons.push({
                        menu_item_addon_id: addon.id,
                        quantity: itemCount,
                        description: '',
                    });
                }
            });
        };
        formatSubAddons(addons);
        return formattedItem;
    });
    return JSON.stringify(formattedItems);
};

export const getItemsAndTotal = (cartItems, itemsInstructionData = {}) => {
    let totalTaxes = 0;
    let itemTotal = 0;
    let fetchedcartItemList = cartItems?.map((item) => {
        let itemPrice = 0;
        let itemTax = 0;
        let addonPrice = 0
        const tempSubItem = {
            id: item.id,
            itemName: item.name,
            type: item.type,
            addons: [], // contains addon-list and sub addons list,
            itemPrice,
            itemTax,
            itemCount: item.cart_item_count,
            cartItemId: item.cartItemId,
            itemActualPrice: item.price,
            thumbnail: item.thumbnail,
            instruction: itemsInstructionData[item.cartItemId] || '',
        };

        if (item.price > 0) {
            itemPrice += item.price;
        }

        // adding selected addons list (also adding price and taxes)
        const updateTempSubItem = (addOns, title = null) => {
            addOns?.forEach((addOn) => {
                if (addOn.quantity > 0) {
                    const addonMetaData = {
                        id: addOn.id,
                        title: title,
                        name: addOn.name,
                        quantity: item.cart_item_count,
                        addonPrice: addOn.price,
                    };
                    tempSubItem.addons.push(addonMetaData);
                    if (addOn.price > 0) {
                        const totalAddonPrice = addOn.price * item.cart_item_count;
                        addonPrice += totalAddonPrice;
                    }
                }
            });
        };
        // adding sub-addon category and without sub-addon items in a single place
        item?.sub_addons?.forEach((subAdd) => {
            updateTempSubItem(subAdd.addons, subAdd.name);
        });
        updateTempSubItem(item.without_subaddon_addons);
        tempSubItem.itemPrice = (itemPrice * item.cart_item_count) + addonPrice; // updating item price
        itemTotal += tempSubItem.itemPrice; // updating total price
        // tempSubItem.itemTax = itemTax * item.cart_item_count; // updating item tax
        // totalTaxes += tempSubItem.itemTax; // updating total taxes
        return tempSubItem;
    });
    let itemsTotal = itemTotal + totalTaxes;
    return { fetchedcartItemList, itemsTotal };
};