import React from 'react';
import { themeColors } from '../../theme';
import {
    EditOutlined,
    EyeFilled,
    EyeInvisibleOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { Button, Input } from 'antd';

const Login = (props) => {
    const {
        userData,
        inputChangeHandler,
        loginBtn,
        loading,
        setCurrentView,
        hidePassword,
        setHidePassword,
        translation,
    } = props;

    return (
        <div className='flex flex-col w-full'>
            <div className='mb-2'>
                <div className='text-sm '>{translation['login_with_us']}</div>
            </div>
            <div className='mb-2'>
                <div className='relative'>
                    <div className='mb-2 flex flex-row gap-2'>
                        <Input
                            className='h-12 border rounded-xl border-gray-300 p-3 w-10 bg-gray-100 text-center'
                            placeholder='Country code'
                            onChange={(e) =>
                                inputChangeHandler(
                                    'mobile_country_code',
                                    e.target.value
                                )
                            }
                            value={`+${userData.mobile_country_code}`}
                            maxLength={4}
                            autoFocus
                            style={{ width: 70 }}
                            disabled={true}
                        />
                        <Input
                        type='number'
                        pattern='[0-9]*'
                        inputMode='numeric'
                            className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                            placeholder={translation['enter_mobile_number']}
                            onChange={(e) =>
                                inputChangeHandler('mobile', e.target.value)
                            }
                            value={userData.mobile}
                            maxLength={12}
                            autoFocus
                        />
                    </div>
                    <div
                        onClick={() => setCurrentView('mobile')}
                        className='flex '
                    >
                        <div className='absolute bg-white w-8 h-8 top-2 right-2  flex justify-center items-center border border-gray-400 p-1 rounded-full'>
                            <EditOutlined />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex relative mb-2 w-full'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder='Enter Password'
                    onChange={(e) =>
                        inputChangeHandler('password', e.target.value)
                    }
                    value={userData.password}
                    maxLength={12}
                    autoFocus
                    type={hidePassword ? 'text' : 'password'}

                />
                <div
                    className='text-xs mt-3.5 absolute right-4 top-0 bottom-0'
                    style={{
                        color: themeColors.primaryColor,
                    }}
                    onClick={() => setHidePassword((prevState) => !prevState)}
                >
          {hidePassword ? <EyeInvisibleOutlined /> : <EyeFilled />}
                </div>
            </div>
            <div className=' flex flex-row mt-2 items-center justify-center'>
                <Button
                    className='flex flex-row bg-gray-50 p-3 px-10 rounded-full shadow w-36 h-11 justify-center items-center'
                    style={{
                        backgroundColor: themeColors.primaryColor,
                    }}
                    onClick={loginBtn}
                    disabled={loading}
                >
                    {loading ? (
                        <LoadingOutlined />
                    ) : (
                        <div className='text-sm font-medium text-white'>
                            {translation['login']}
                        </div>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default Login;
