

const isObject = (value) =>
    typeof value === 'object' && !Array.isArray(value) && value !== null;

const storeData = async (key, value) => {
    try {
        const jsonValue = isObject(value) ? JSON.stringify(value) : value;
        await localStorage.setItem(key, jsonValue);
    } catch (e) {
        // saving error
    }
};
const getData = async (key) => {
    try {
        const jsonValue = await localStorage.getItem(key);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        // error reading value
    }
};
const removeData = async (key) => {
    try {
      await localStorage.removeItem(key)
    } catch(e) {
      // remove error
    }
  }
  const clearStorage = async()=>{
    try {
        const res = await localStorage.clear()
    } catch (error) {
        
    }
}
export { storeData, getData,removeData,clearStorage };
