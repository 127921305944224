import React from 'react';
import { themeColors } from '../theme';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAddressList,
    hotelSelector,
    updateSelectedAddress,
} from '../store/slices/hotel/hotelSlice';
import { useLanguage } from '../context/LanguageContext';
import {
    EnvironmentOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { SERVICETYPE } from '../services/Typecode';

const AddressList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { translation } = useLanguage();
    const { loading, error, addressList } = useSelector(hotelSelector);
    const serviceType = localStorage.getItem(SERVICETYPE);

    React.useEffect(() => {
        if (serviceType === 'delivery') {
            dispatch(fetchAddressList());
        }
        window.scrollTo(0, 0);
    }, []);

    const selectedAddressClickHandler = (address) => {
        dispatch(updateSelectedAddress(address));
        navigate(-1);
    };

    return (
        <div className='flex flex-col'>
            {loading ? (
                <div className='flex flex-col flex-1 gap-2 justify-center items-center '>
                    <LoadingOutlined />
                    <div
                        className='text-xs text-center '
                        style={{ color: themeColors.primaryColor }}
                    >
                        Loading
                    </div>
                </div>
            ) : error ? (
                <div className='flex-col flex-1 gap-2 justify-center items-center '>
                    <div
                        className='text-xs text-center '
                        style={{ color: 'red' }}
                    >
                        {translation['no_orders_placed_yet']}
                    </div>
                </div>
            ) : addressList.length === 0 ? (
                <div>{translation['address_not_added']}</div>
            ) : (
                addressList.map((address) => (
                    <div
                        className={
                            'flex bg-white rounded-2xl mb-3 flex-1 shadow border border-gray-200 flex-row p-2'
                        }
                        key={address.id}
                        style={{
                            backgroundColor: !address.is_deliverable
                                ? '#afafaf'
                                : 'white',
                        }}
                    >
                        <div className='relative flex flex-row w-full justify-between items-start '>
                            <div className='flex flex-row gap-x-2 '>
                                <EnvironmentOutlined
                                    style={{ color: themeColors.primaryColor }}
                                />
                                <div className=' flex flex-col w-64 justify-start items-start '>
                                    <div
                                        className='flex rounded-full bg-black p-0.5 px-3'
                                        style={{
                                            backgroundColor:
                                                themeColors.bgColor(0.5),
                                        }}
                                    >
                                        <div
                                            className='flex text-black text-sm text-left '
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {address.title || translation['other']}
                                        </div>
                                    </div>
                                    <div className='flex text-gray-500 text-left text-xs'>
                                        {address.address.split('|').join(' ')}
                                    </div>
                                </div>
                            </div>

                            {address.is_deliverable && (
                                <div
                                    className=' rounded-full p-0'
                                    onClick={() =>
                                        selectedAddressClickHandler(address)
                                    }
                                    disabled={!address.is_deliverable}
                                >
                                    <div className='text-sm text-blue-500 rounded-full'>
                                        {translation['select']}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default AddressList;
