import axios from 'axios';

//  axio header setup
axios.defaults.baseURL =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? `https://vserve-api.valet2you.in/`
        : `https://test-vserve-api.valet2you.in/`;

axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const getToken = () => {
    try {
        const res = localStorage.getItem('token');
        return res;
    } catch (error) {}
};
// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        const token = getToken();
        if (token) {
            // config.headers.common['Authorization'] = 'Bearer ' + token;
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // if(config && config.url){
        //     config[url] = `${config.url}?lang='hi`;
        // }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const { response, ...errorObj } = error;
        if (response.status === 401) {
            // removeUserDataFromBrowserStorage();
            // window.location.replace("/login")
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

const getHeaders = (token) => {
    return {
        headers: {
            Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        },
    };
};
export const checkUser = (payload) => {
    return axios.post('api/v5.0.1/outlet/guest/login_check', payload);
};
export const loginWithPassword = (payload) => {
    return axios.post('api/v5.0.1/login', payload);
};

export const forgetPassword = (payload) => {
    return axios.post(`api/v5.0.1/reset-password`, payload);
};
export const changePassword = (payload) => {
    return axios.post(`api/v5.0.1/change-password`, payload);
};

export const registerUserWithPassword = (payload) => {
    return axios.post('api/v5.0.1/register-guest', payload);
};

export const logout = () => {
    return axios.get('api/v5.0.1/logout');
};
export const getOrderHistory = (token) => {
    // const headers = getHeaders(token);
    return axios.get('api/v5.0.1/outlet/guest/orders');
};
export const getOrderDetails = (orderID, lang) => {
    // const headers = getHeaders(token);
    return axios.get(
        `api/v5.0.1/outlet/guest/order/${orderID}?lang_code=${lang}`
    );
};

// google services
export const getUserPostalAddress = (latlng) => {
    return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=AIzaSyByaDHym0A1ItGyWo-aNdPROaLd_NrekRg`
    );
};
export const getHotelDetails = () => {
    const hotelID = localStorage.getItem('__hotelID');
    return axios.get(`api/v5.0.1/outlet/guest/app/property/${hotelID}`);
};
export const placeFoodOrder = (payload) => {
    const headers = {
        'X-DEVICE-TYPE': 'web',
    };
    return axios.post('api/v5.0.1/outlet/guest/order', payload, { headers });
};
export const ValidateAddress = (id, data) => {
    let params = new URLSearchParams(data).toString();
    return axios.get(
        `api/v5.0.1/outlet/guest/address/distance/${id}?${params}`
    );
};
export const addNewAddresss = (data) => {
    return axios.post('api/v5.0.1/user/address', data);
};

export const getRepeatOrderDetails = (orderid, lang) => {
    return axios.get(`api/v5.0.1/guest/previous-food-order-detail?order_id=${orderid}&lang_code=${lang}`);
};
