import { Input, theme } from 'antd';
import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import { ColorFactory } from 'antd/es/color-picker/color';
import { themeColors } from '../theme';

const BonvoyMember = (props) => {
    const { membershipNo, setMembershipNo } = props;
    const { translation } = useLanguage();

    return (
        <div className='flex flex-col bg-white p-3 rounded-xl mb-4 flex-1 shadow'>
            <div className='flex flex-1 justify-between flex-col'>
                <div className='text-sm text-black'>
                    {translation['enter_membership_no']}
                </div>
            </div>

            <Input
                type='text'
                className=' border rounded-lg border-gray-300 p-2 py-3 mt-2 text-xs'
                placeholder={translation['enter_membership_no']}
                value={membershipNo}
                onChange={(e) => setMembershipNo(e.target.value)}
            />
            <div className='text-xs  text-gray-500 mt-1'>
                <span>{translation['if_not_membership']} </span>{" "}
                <a
                    href='https://www.marriott.com/loyalty/join/joinPromotion.mi?promotion=HE24&nst=paid&cid=PAI_GLB00050K5_GLE000BM4G_GLF000OP2I&ppc=ppc&pId=corpimedia&gad_source=1&gclid=Cj0KCQjwwMqvBhCtARIsAIXsZpav_bmstGKgwQoY8D5t89oIkPFA6KOqC0iONG84rMaVq4EAuwkVDJMaAs9nEALw_wcB&gclsrc=aw.ds'
                    className='underline'
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: themeColors.primaryColor }}
                >
                    {translation['click_Here']}
                </a>
            </div>
        </div>
    );
};

export default BonvoyMember;
