import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import axios from 'axios';

import {
    CART_ITEMS,
    OUTLET_ID,
    SERVICETYPE,
    USER_LOCATION,
} from '../../../services/Typecode';
import { ValidateAddress } from '../../../services/apiService';
const hotelSlice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        updateLoading(state, { payload }) {
            state.loading = payload;
        },
        updateMenus(state, { payload }) {
            state.menuList = payload;
        },
        updateError(state, { payload }) {
            state.error = payload;
        },
        updateHotelDetails(state, { payload }) {
            state.hotelDetails = payload;
        },
        updateCategorywithItems(state, { payload }) {
            state.categorywithItems = payload;
        },
        updateAddressList(state, { payload }) {
            state.addressList = payload;
        },
        updateSelectedAddress(state, { payload }) {
            state.selectedAddress = payload;
        },
        updateExistingLocalCart(state, { payload }) {
            state.existingCartItems = payload;
        },
        updateItemsInstructionMessage(state, { payload }) {
            let res = { ...state.itemsInstructionMessages, ...payload };
            state.itemsInstructionMessages = res;
        },
    },
});

export const hotelSelector = (state) => state.hotelSlice;
export const {
    updateLoading,
    updateMenus,
    updateError,
    updateHotelDetails,
    updateCategorywithItems,
    updateAddressList,
    updateSelectedAddress,
    updateExistingLocalCart,
    updateItemsInstructionMessage
} = hotelSlice.actions;

export default hotelSlice.reducer;

export function fetchMenus(lang, locationObj) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            dispatch(updateMenus([]));
            const stringDatLatLong = localStorage.getItem(USER_LOCATION);
            let latLong = {};
            if (locationObj) {
                latLong = locationObj;
            } else {
                latLong = stringDatLatLong
                    ? JSON.parse(stringDatLatLong)
                    : { lat: '', long: '' };
                //   { lat: '25.3288845', long: '51.5303094' };
            }
            const serviceType = localStorage.getItem(SERVICETYPE);
            const hotelID = localStorage.getItem('__hotelID');

            const response = await axios.get(
                `/api/v5.0.1/outlet/guest/menu/${hotelID}?lat=${latLong.lat}&long=${latLong.long}&service_type=${serviceType}&lang_code=${lang}`
            );
            if (response.status === 200) {
                let menus = response.data.data.menus || [];
                let outletID = response.data.data.id || '';
                // menus = menus.filter((item) => Boolean(item.enabled));
                localStorage.setItem(OUTLET_ID, outletID);
                dispatch(updateMenus(menus || []));
                // dispatch(updateHotelDetails(data));
            }
        } catch (error) {
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
//
export function fetchCategorywithItems(menuID, lang) {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            dispatch(updateCategorywithItems([]));
            if (!menuID) menuID = await localStorage.getItem('menuID');
            const response = await axios.get(
                `/api/v5.0.3/outlet/guest/outlet/menu/categories/${menuID}?lang_code=${lang}`
            );
            if (response.status === 200) {
                let existingCartItems = await localStorage.getItem(CART_ITEMS);
                existingCartItems = existingCartItems
                    ? JSON.parse(existingCartItems)
                    : [];
                const { menu_categories = [] } = response.data.data || {};
                // let filtered =
                //     menu_categories.filter((cat) => Boolean(cat.enabled)) || [];
                dispatch(updateExistingLocalCart(existingCartItems));
                dispatch(updateCategorywithItems(menu_categories));
                // dispatch(updateCategorywithItems([menu_categories[0]]));
            }
        } catch (error) {
        } finally {
            dispatch(updateLoading(false));
        }
    };
}
export function fetchAddressList() {
    return async (dispatch) => {
        try {
            dispatch(updateLoading(true));
            dispatch(updateAddressList([]));
            dispatch(updateSelectedAddress({}));
            const response = await axios.get(`api/v5.0.1/user/address`);
            if (response.status === 200) {
                let addresses = response.data.data || {};
                addresses = addresses.sort((a, b) => b - a);
                const outletID = localStorage.getItem(OUTLET_ID);
                if (addresses.length) {
                    let addressListPromise = [];
                    for (let i = 0; i < addresses.length; i++) {
                        const params = {
                            lat: addresses[i].latitude,
                            long: addresses[i].longitude,
                            id: addresses[i].id,
                        };
                        const p = ValidateAddress(outletID, params);
                        addressListPromise.push(p);
                    }
                    const result = await Promise.all(addressListPromise);
                    result.forEach((address, i) => {
                        addresses[i]['is_deliverable'] =
                            address?.data?.data?.is_deliverable || false;
                        addresses[i]['delivery_charge'] =
                            address?.data?.data?.delivery_charge || 0;
                    });
                    const firstdeliverable = addresses.find(
                        (item) => item.is_deliverable
                    );
                    dispatch(updateAddressList(addresses));
                    dispatch(updateSelectedAddress(firstdeliverable));
                }
            }
        } catch (error) {
            throw new Error('Promise failed');
        } finally {
            dispatch(updateLoading(false));
        }
    };
}

export const updateUserProps = () => {};
