import React from 'react';

const HTMLContent = ({ html }) => {
    return (
        <div
            dangerouslySetInnerHTML={{__html: html}}
        />
    );
};

export default HTMLContent;
