import React from 'react';
import { themeColors } from '../theme';
import {
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';

const Counter = ({ count, increase, decrease }) => {
    return (
        <div className='flex flex-row justify-center items-center gap-x-2 mt-1'>
            <div
                className='flex bg-gray-50 rounded-full p-1 shadow-lg border'
                style={{
                    backgroundColor: themeColors.primaryColor,
                    borderColor: themeColors.primaryColor,
                }}
                onClick={decrease}
            >
                <MinusOutlined  style={{color:'#fff'}}/>
            </div>
            <div>
                <div className='text-md font-semibold'>{count}</div>
            </div>
            <div
                className='flex bg-gray-50 rounded-full p-1 shadow-lg border'
                style={{
                    backgroundColor: themeColors.primaryColor,
                    borderColor: themeColors.primaryColor,
                }}
                onClick={increase}
            >
                <PlusOutlined style={{color:'#fff'}}/>
            </div>
        </div>
    );
};

export default Counter;
