import React, { useState } from 'react';

import { themeColors } from '../theme';
import { useNavigate } from 'react-router-dom';

import { getUserPostalAddress } from '../services/apiService';
import { useDispatch, useSelector } from 'react-redux';
import {
    LAST_PAGE_VISIT,
    USER_LOCATION,
} from '../services/Typecode';
import { useAuth } from '../context/AuthContext';

import { useLanguage } from '../context/LanguageContext';
import { fetchMenus, hotelSelector } from '../store/slices/hotel/hotelSlice';
import { Button, Drawer, notification } from 'antd';
import {
    ArrowLeftOutlined,
    CloseCircleFilled,
    LoadingOutlined,
} from '@ant-design/icons';
import Autocomplete from 'react-google-autocomplete';

const HomeScreen = () => {
    const locaionInputRef = React.useRef(null);
    const [userLocation, setUserLocation] = useState('');
    const { authState, logOutClickHandler } = useAuth();
    const [api, contextHolder] = notification.useNotification();

    const [languageModal, setLanguageModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { menuList, loading } = useSelector(hotelSelector);
    const {
        langList,
        changeLanguage,
        lang = 'en',
        translation,
    } = useLanguage();

    React.useEffect(() => {
        getLocationFromLatLong();
        // clearStorage();
    }, []);

    React.useEffect(() => {
        dispatch(fetchMenus(lang));
    }, []);

    const getLocationFromLatLong = async () => {
        try {
            const stringDatLatLong = await localStorage.getItem(USER_LOCATION);
            const latLong = JSON.parse(stringDatLatLong);
            const response = await getUserPostalAddress(
                `${latLong.lat},${latLong.long}`
            );
            const data = await response.json();
            if (data?.status === 'OK') {
                let fullAddress = data?.results?.[0]?.formatted_address || '';
                if (fullAddress) {
                    setUserLocation(fullAddress);
                }
            }
        } catch (error) {}
    };

    const changeBtnClickHandler = (e) => {
        if (locaionInputRef && locaionInputRef.current) {
            locaionInputRef.current.select();
        }
    };
    const continueClickHandler = () => {
        if (
            !userLocation ||
            !userLocation.trim() ||
            !userLocation.trim().length
        ) {
            api.open({
                description: translation['enter_your_location_to_proceed'],
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });
            return;
        }
        if (loading) {
            api.open({
                description: 'Fetching results...',
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });
            return;
        }
        if (menuList && menuList.length > 0) {
            navigate('/menulist');
            return;
        } else {
            api.open({
                description: translation['location_not_serviceable'],
                icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
            });
        }
    };

    const orderHistoryClickHandler = (e) => {
        if (authState.authenticated) {
            navigate('/orderhistory');
        } else {
            storeLastLocation('orderhistory');
            navigate('/login');
        }
    };
    const storeLastLocation = async (pageName = '', cb) => {
        try {
            await localStorage.setItem(LAST_PAGE_VISIT, pageName);
            if (cb) cb();
        } catch (error) {}
    };
    const loginClickHandler = (e) => {
        storeLastLocation('home');
        navigate('/login');
    };
    const logout = () => {
        logOutClickHandler();
        setProfileModal(false);
    };

    // bottom sheeet
    const changeLanguageHandler = (lang) => {
        setLanguageModal(false);
    };
    return (
        <div
            className='flex flex-col h-screen justify-center align-center relative'
            style={{ backgroundColor: themeColors.primaryColor }}
        >
            {contextHolder}

            <div
                className='fixed flex top-2 left-4 bg-gray-50 rounded-full p-2 shadow-lg z-9'
                onClick={() => navigate('/', { replace: true })}
            >
                <ArrowLeftOutlined
                    style={{ color: themeColors.primaryColor }}
                />
            </div>

            <div className='flex flex-col justify-center'>
                <div className='img flex justify-center'>
                    <img
                        className='max-w-60'
                        src='https://master-vserve.s3.ap-south-1.amazonaws.com/property/1694454283-home_logo-Pure white.png'
                        alt='logo'
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <div className='flex-1 justify-center items-left px-4'>
                        <div className='w-full justify-center items-center mb-2'></div>

                        <div className='text-white text-1xl text-left mb-2 pl-2'>
                            {translation['confirm_your_delivery_location']}
                        </div>
                        <div className='flex relative items-center'>
                            {/* <GooglePlacesAutocomplete
                                className='w-full text-xs h-10 flex justify-between items-center relative bg-white  flex-row px-3  rounded-full'
                                apiKey='AIzaSyByaDHym0A1ItGyWo-aNdPROaLd_NrekRg'
                                apiOptions={{ language: 'ar', region: 'ar' }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['us', 'ca', 'uy'],
                                    },
                                }}
                                selectProps={{
                                    styles: {
                                        input: (provided) => ({
                                            ...provided,
                                            color: 'blue',
                                            width:'100%',
                                            backgroundColor:'#fff'
                                        }),
                                        //   option: (provided) => ({
                                        //     ...provided,
                                        //     color: 'blue',
                                        //   }),
                                        //   singleValue: (provided) => ({
                                        //     ...provided,
                                        //     color: 'blue',
                                        //   }),
                                    },
                                }}
                            /> */}

                            <Autocomplete
                                ref={locaionInputRef}
                                className='w-full text-xs h-10 flex justify-between items-center relative bg-white  flex-row px-3  rounded-full'
                                apiKey={
                                    'AIzaSyByaDHym0A1ItGyWo-aNdPROaLd_NrekRg'
                                }
                                inputAutocompleteValue={userLocation}
                                defaultValue={userLocation}
                                onPlaceSelected={(place) => {
                                    if (place?.formatted_address) {
                                        setUserLocation(
                                            place?.formatted_address
                                        );
                                    }
                                    const userData = {
                                        lat:
                                            place?.geometry?.location?.lat() ||
                                            '',
                                        long:
                                            place?.geometry?.location?.lng() ||
                                            '',
                                    };
                                    localStorage.setItem(
                                        USER_LOCATION,
                                        JSON.stringify(userData)
                                    );
                                    dispatch(fetchMenus(lang));
                                }}
                                placeholder={
                                    translation['enter_your_delivery_location']
                                }
                                options={{
                                    types: ['establishment'],
                                    componentRestrictions: { country: 'qa' },
                                }}
                            />
                            <Button
                                className='text-xs absolute right-2 top-0 z-9 border-none p-0 bg-white pl-2'
                                style={{
                                    color: themeColors.primaryColor,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                                onClick={changeBtnClickHandler}
                            >
                                {translation['change']}
                            </Button>
                        </div>
                        <div className='flex flex-row justify-center items-center m-4'>
                            <div
                                className='w-32 flex-row h-11 bg-gray-50 p-3 px-6 rounded-lg shadow justify-center text-center'
                                onClick={continueClickHandler}
                                disabled={loading}
                            >
                                {loading ? (
                                    <LoadingOutlined />
                                ) : (
                                    <div
                                        className='text-sm font-medium	'
                                        style={{
                                            color: themeColors.primaryColor,
                                        }}
                                    >
                                        {translation['continue']}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div className='absolute bottom-4 left-4 right-4'>
                        <div  className='text-xs  text-center text-gray-400'>
                            {translation['home_disclaimer_msg']}
                        </div>
                    </div> */}
                </div>
            </div>

            <Drawer
                placement={'bottom'}
                closable={false}
                onClose={() => setProfileModal(false)}
                open={profileModal}
            >
                <div className=' bg-white rounded-lg flex flex-col gap-2'>
                    <div
                        className='flex flex-row justify-start items-center p-3 rounded-xl bg-slate-200'
                        onClick={orderHistoryClickHandler}
                    >
                        <div className='text-sm font-medium text-black	'>
                            {translation['yourOrders']}
                        </div>
                    </div>
                    {authState.authenticated ? (
                        <div
                            className='flex flex-row justify-start items-center  p-3 rounded-xl bg-slate-200'
                            onClick={logout}
                        >
                            <div className='text-sm font-medium	text-black'>
                                {translation['logout']}
                            </div>
                        </div>
                    ) : (
                        <div
                            className='flex flex-row justify-start items-center  p-3 rounded-xl bg-slate-200'
                            onClick={loginClickHandler}
                        >
                            <div className='text-sm font-medium	text-black'>
                                {translation['login']}
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default HomeScreen;
