import React, { useEffect } from 'react';
import { themeColors } from '../theme';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchMenus, hotelSelector } from '../store/slices/hotel/hotelSlice';
import MenuCard from '../components/MenuCard';
import { useLanguage } from '../context/LanguageContext';
import {
    ArrowLeftOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import DisclaimerComponent from '../components/DisclaimerComponent';
import { SERVICETYPE } from '../services/Typecode';
const MenuListScreen = () => {
    const dispatch = useDispatch();
    const { loading, menuList, error } = useSelector(hotelSelector);
    const { translation, lang } = useLanguage();
    const navigate = useNavigate();
    const serviceType = localStorage.getItem(SERVICETYPE);

    useEffect(() => {
        let locationObj = { lat: '25.3288845', long: '51.5303094' }
        if(serviceType ==='takeaway'){
            dispatch(fetchMenus(lang,locationObj));
        }else{
            dispatch(fetchMenus(lang));
        }
    }, []);

    let filteredMenu = menuList.filter((item) => Boolean(item.enabled));

    return (
        <div className='flex-1 '>
            <div
                className='sticky top-0 w-full flex flex-row justify-between items-center px-4 py-2 z-10 shadow'
                style={{ backgroundColor: themeColors.primaryColor }}
            >
                <div
                    className='flex rounded-full p-2 shadow-lg bg-white'
                    onClick={() => navigate(-1)}
                >
                    <ArrowLeftOutlined
                        style={{ color: themeColors.primaryColor }}
                    />
                </div>
                <div></div>
                <DisclaimerComponent message={translation['disclaimer_msg']} />
            </div>
            <div
                className='flex justify-center items-center relative h-48 w-full flex-col'
                style={{ backgroundColor: themeColors.primaryColor }}
            >
                <img
                    style={{ width: '200px' }}
                    src={
                        'https://master-vserve.s3.ap-south-1.amazonaws.com/property/1694454283-home_logo-Pure%20white.png'
                    }
                    alt='logo'
                />
                <div className='text-white text-xs'>
                   {translation['hungry_got_you_covered']}
                </div>
            </div>

            <div
                // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
                className='flex flex-col bg-white  flex-1  pt-6'
            >
                <div className='px-4 '>
                    <div
                        className='text-lg font-bold text-left'
                        style={{ color: themeColors.primaryColor }}
                    >
                        {translation['explore_our_menu']}
                    </div>
                    <div
                        className='text-xs text-left'
                        style={{ color: themeColors.primaryColor }}
                    >
                        {translation['meal_crafted_menu_items']}
                    </div>
                </div>
                <div className='flex-1 p-4 pb-10'>
                    {loading ? (
                        <div className='flex flex-col flex-1 gap-2 justify-center items-center '>
                            <LoadingOutlined />
                            <div
                                className='text-xs text-center '
                                style={{ color: themeColors.primaryColor }}
                            >
                                Loading
                            </div>
                        </div>
                    ) : error ? (
                        <div className='flex flex-col flex-1 gap-2 justify-center items-center '>
                            <InfoCircleOutlined color='red' />
                            <div
                                className='text-xs text-center '
                                style={{ color: 'red' }}
                            >
                               {translation['something_went_wrong']}
                            </div>
                        </div>
                    ) : filteredMenu.length === 0 ? (
                        <div className='text-xs text-gray-400 font-normal flex-wrap'>
                            {serviceType === 'delivery'? translation['menu_not_found_msg']:''}
                        </div>
                    ) : (
                        filteredMenu.map((menu, index) => (
                            <MenuCard key={menu.id + index} {...menu} />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default MenuListScreen;
