const initial = {
    loading: true,
    menuList: [],
    error: '',
    hotelDetails: {},
    categorywithItems: [],
    addressList: [],
    selectedAddress: {},
    existingCartItems: [],
    itemsInstructionMessages: {},
};
export default initial;
