import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import LanguageContextProvider from './context/LanguageContext';
import HotelContextProvider from './context/HotelContext';
import AuthContextProvider from './context/AuthContext';
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));
const TRACKING_ID = "G-5KFVTY7MCF"; // your Measurement ID
ReactGA.initialize(TRACKING_ID);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <HotelContextProvider>
                    <LanguageContextProvider>
                        <AuthContextProvider>
                            <App />
                        </AuthContextProvider>
                    </LanguageContextProvider>
                </HotelContextProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
