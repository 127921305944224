import React from 'react';
import { themeColors } from '../../theme';
import { EyeFilled, EyeInvisibleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const ChangePassword = (props) => {
    const {
        userData,
        inputChangeHandler,
        loading,
        changePasswordBtn,
        old_password,
        setOldPassword,
        setCurrentView,
        hidePassword,
        setHidePassword,
        translation,
    } = props;
    return (
        <div className='flex flex-col w-full'>
            <div className='mb-2'>
                <div className='text-sm'>
                    {translation['change_your_password']}
                </div>
            </div>
            <div className='mb-2 flex flex-row gap-2 w-full'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3 w-10 bg-gray-100 text-center'
                    placeholder='Country code'
                    onChange={(e) =>
                        inputChangeHandler('mobile_country_code', e.target.value)
                    }
                    
                    value={`+${userData.mobile_country_code}`}
                    maxLength={4}
                    autoFocus
                    style={{ width: 70 }}
                    disabled={true}
                />
                <Input
                type='number'
                pattern='[0-9]*'
                inputMode='numeric'
                    className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                    placeholder={translation['enter_mobile_number']}
                    onChange={(e) => inputChangeHandler('mobile', e.target.value)}
                    
                    value={userData.mobile}
                    maxLength={12}
                    autoFocus
                />
            </div>
            <div className='flex mb-2 gap-x-2 w-full relative'>
                <Input

                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['enter_old_password']}
                    onChange={(e) => setOldPassword(e.target.value)}
                    value={old_password}
                />
            </div>
            <div className='flex mb-2 gap-x-2 relative'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['enter_new_password']}
                    onChange={(e) => inputChangeHandler('password', e.target.value)}
                    value={userData.password}
                    secureTextEntry={hidePassword}
                    type={hidePassword ? 'text' : 'password'}

                />
                <div
                    className='text-xs mt-3.5 absolute right-4 top-0 bottom-0'
                    style={{
                        color: themeColors.primaryColor,
                    }}
                    onClick={() => setHidePassword((prevState) => !prevState)}
                >
                   
                   {hidePassword ? <EyeInvisibleOutlined /> : <EyeFilled />}
                </div>
            </div>
            <div className='flex mb-2 gap-x-2  relative'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['confirm_new_password']}
                    onChange={(e) =>
                        inputChangeHandler('password_confirmation', e.target.value)
                    }
                    value={userData.password_confirmation}
                    secureTextEntry={hidePassword}
                    type={hidePassword ? 'text' : 'password'}

                />
                <div
                    className='text-xs mt-3.5 absolute right-4 top-0 bottom-0'
                    style={{
                        color: themeColors.primaryColor,
                    }}
                    onClick={() => setHidePassword((prevState) => !prevState)}
                >
              {hidePassword ? <EyeInvisibleOutlined /> : <EyeFilled />}
                </div>
            </div>
            <div className='flex flex-col mt-2 items-center justify-center'>
                <div
                    className='flex-row bg-gray-50 p-3 px-8 rounded-full shadow h-11 justify-center items-center'
                    style={{
                        backgroundColor: themeColors.primaryColor,
                    }}
                    onClick={changePasswordBtn}
                    disabled={loading}
                >
                    {loading ? (
                        <LoadingOutlined />
                    ) : (
                        <div className='text-sm font-medium text-white'>
                            {translation['change_password']}
                        </div>
                    )}
                </div>
                <div
                    className='flex-row justify-center items-center mt-2 ml-auto'
                    onClick={() => setCurrentView('mobile')}
                    disabled={loading}
                >
                    <div
                        className='text-sm font-medium  '
                        style={{ color: themeColors.primaryColor }}
                    >
                        {translation['back_to_login']}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
