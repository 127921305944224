import React, { createContext, useContext, useState } from 'react';
import {
    changePassword,
    checkUser,
    forgetPassword,
    loginWithPassword,
    logout,
    registerUserWithPassword,
} from '../services/apiService';
import { CART_ITEMS, TOKEN, USER_PROPS } from '../services/Typecode';
import { notification } from 'antd';
import axios from 'axios';
import {
    CheckCircleFilled,
    CloseCircleFilled,
} from '@ant-design/icons';
import { useLanguage } from './LanguageContext';

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
    const [currentView, setCurrentView] = useState('mobile');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [authState, setAuthState] = React.useState({
        authenticated: false,
        token: null,
    });
    const [api, contextHolder] = notification.useNotification();
    const {translation}=useLanguage();

    React.useEffect(() => {
        updateAuthState();
    }, []);

    React.useEffect(() => {
        setError('');
    }, [currentView]);

    const updateAuthState = async () => {
        try {
            setLoading(true);
            const data = await localStorage.getItem(TOKEN);
            if (data) {
                setAuthState({ authenticated: true, token: data });
                axios.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${data}`;
            } else {
                setAuthState({
                    authenticated: false,
                    token: null,
                });
                await localStorage.removeItem(TOKEN);
                await localStorage.removeItem(USER_PROPS);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const continueClickHandler = async (payload) => {
        try {
            setLoading(true);
            setError('');
            const response = await checkUser(payload);
            if (response.status === 200) {
                setCurrentView('login');
            }
        } catch (err) {
            const { response = {} } = err;
            if (response.status === 404) {
                setCurrentView('register');
            } else {
                setError('Something went wrong! Please try again');
            }
        } finally {
            setLoading(false);
        }
    };
    const loginClickHandler = async (payload) => {
        try {
            setLoading(true);
            setError('');
            const response = await loginWithPassword(payload);
            if (response.status === 200) {
                let tokenValue = response?.data?.access_token || '';
                // await storeData('token', tokenValue);
                axios.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${tokenValue}`;
                setAuthState({
                    authenticated: true,
                    token: tokenValue,
                });
                await localStorage.setItem(TOKEN, tokenValue);
                let userInfo = {
                    mobile: payload?.username || '',
                    isverified: true,
                };
                await localStorage.setItem(
                    USER_PROPS,
                    JSON.stringify(userInfo)
                );
                api.open({
                    description: translation['you_have_logged_in'],
                    icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
                });
            }
        } catch (err) {
            const { response = {} } = err;
            if (response.status === 404) {
                setCurrentView('register');
            } else if (response.status === 401) {
                api.open({
                    description: translation['incorrect_password_try_again'],
                    icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
                });
            } else {
                setError('Something went wrong! Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const registerUserClickHandler = async (payload) => {
        try {
            setLoading(true);
            setError('');
            const response = await registerUserWithPassword(payload);
            if (response.status === 201) {
                let tokenValue = response?.data?.data?.access_token || '';
                axios.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${tokenValue}`;
                setAuthState({
                    authenticated: true,
                    token: tokenValue,
                });
                localStorage.setItem(TOKEN, tokenValue);
                let userInfo = {
                    mobile: payload.mobile,
                    isverified: true,
                };
                localStorage.setItem(
                    USER_PROPS,
                    JSON.stringify(userInfo)
                );
                api.open({
                    description: translation['registered_successfully'],
                    icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
                });
            }
        } catch (err) {
            const { response = {} } = err;
            if (response.status === 404) {
                setCurrentView('register');
            } else if (response.status === 400) {
                const msg = response.data.message || '';
                setError(msg);
            } else {
                setError('Something went wrong! Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };
    const logOutClickHandler = async () => {
        try {
            const response = await logout();
            if (response.status === 200) {
                setAuthState({
                    authenticated: false,
                    token: null,
                });
                await localStorage.removeItem(TOKEN);
                await localStorage.removeItem(USER_PROPS);
            }
        } catch (error) {
        } finally {
            axios.defaults.headers.common['Authorization'] = '';
            setAuthState({
                authenticated: false,
                token: null,
            });
            api.open({
                description: translation['you_have_logged_out'],
                icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
            });
            await localStorage.removeItem(TOKEN);
            await localStorage.removeItem(USER_PROPS);
            await localStorage.removeItem(CART_ITEMS);
        }
    };
    const forgetPasswordClickHandler = async (payload) => {
        try {
            setLoading(true);
            setError('');
            const response = await forgetPassword(payload);
            if (response.status === 200) {
                const message =
                    response.data.message ||
                    translation['new_password_sent_to_mail'];
                api.open({
                    description: message,
                    icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
                });
                setCurrentView('login');
            }
        } catch (err) {
            const { response = {} } = err;
            if (response.status === 404) {
                api.open({
                    description: translation['no_user_found'],
                    icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
                });
                setCurrentView('mobile');
            } else if (response.status === 401) {
                api.open({
                    description:
                        'Authentication failed. Please try again later.',
                    icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
                });
                setCurrentView('mobile');
            } else {
                setError('Something went wrong! Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };
    const changePasswordClickHandler = async (payload) => {
        try {
            setLoading(true);
            setError('');
            const response = await changePassword(payload);
            if (response.status === 200) {
                const message =
                    response.data.message ||
                    translation['password_updated_successfully'];
                api.open({
                    description: message,
                    icon: <CheckCircleFilled style={{ color: '#136c2e' }} />,
                });
                setCurrentView('login');
            }
        } catch (err) {
            const { response = {} } = err;
            if (response.status === 404) {
                api.open({
                    description: translation['no_user_found'],
                    icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
                });

                setCurrentView('mobile');
            } else if (response.status === 401) {
                api.open({
                    description: translation['incorrect_password_try_again'],
                    icon: <CloseCircleFilled style={{ color: '#FF0000' }} />,
                });

            } else {
                setError('Something went wrong! Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const value = {
        loading,
        error,
        authState,
        currentView,
        continueClickHandler,
        loginClickHandler,
        registerUserClickHandler,
        logOutClickHandler,
        setCurrentView,
        forgetPasswordClickHandler,
        setError,
        changePasswordClickHandler,
    };
    return (
        <AuthContext.Provider value={value}>
            {contextHolder}
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContextProvider;
