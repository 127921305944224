import React from 'react';
import { themeColors } from '../../theme';

import { LAST_PAGE_VISIT } from '../../services/Typecode';
import { useAuth } from '../../context/AuthContext';
import Register from './Register';
import ForgetPassword from './ForgetPassword';
import ChangePassword from './ChangePassword';
import Login from './Login';
import { useLanguage } from '../../context/LanguageContext';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import LogoBlack from '../../assets/logoblack.png';
import { Button, Input, notification } from 'antd';
const LoginScreen = () => {
    const navigate = useNavigate();
    const { translation } = useLanguage();
    const [api, contextHolder] = notification.useNotification();
    const hotelID = localStorage.getItem('__hotelID');

    const [userData, setUserData] = React.useState({
        source: 'delivery-doha',
        device_type: 'web',
        device_token: 'test_abcdefghji',
        hotel_enc_id: hotelID,
        mobile: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        mobile_country_code: '974',
        nationality: '',
    });
    const [old_password, setOldPassword] = React.useState('');
    const [hidePassword, setHidePassword] = React.useState(true);
    const {
        loading,
        error,
        currentView,
        authState,
        registerUserClickHandler,
        loginClickHandler,
        continueClickHandler,
        setCurrentView,
        forgetPasswordClickHandler,
        setError,
        changePasswordClickHandler,
    } = useAuth();

    React.useEffect(() => {
        setCurrentView('mobile');
    }, []);

    React.useEffect(() => {
        if (authState.authenticated) {
            redirectUser();
        }
    }, [authState, authState.authenticated]);

    const redirectUser = async () => {
        try {
            const pageName = await localStorage.getItem(LAST_PAGE_VISIT);
            if (pageName) {
                navigate(`/${pageName}`, { replace: true });
            } else {
                navigate('/');
            }
        } catch (error) {
        } finally {
            setCurrentView('mobile');
        }
    };

    const inputChangeHandler = (name, value) => {
        if (error) {
            setError('');
        }
        setUserData({ ...userData, [name]: value });
    };
    const continueBtn = () => {
        const payload = {
            username: userData.mobile,
        };
        continueClickHandler(payload);
    };
    const loginBtn = async () => {
        const payload = {
            device_type: 'web',
            device_token: 'test_abcdefghji',
            hotel_enc_id: userData.hotel_enc_id,
            password: userData.password,
            username: userData.mobile.toString(),
        };
        loginClickHandler(payload);
    };
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const characterValidate = (data) => {
        return String(data).match(
            /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/
        );
    };
    const registerBtn = () => {
        if (!userData.nationality || !userData.nationality.trim()) {
            api.open({
                description: 'Nationality is required!',
            });
            return;
        } else if (!userData.mobile || !userData.mobile.trim()) {
            api.open({
                description: translation['mobile_no_required'],
            });
            return;
        } else if (!userData.first_name || !userData.first_name.trim()) {
            api.open({
                description: translation['first_name_required'],
            });
            return;
        } else if (!userData.last_name || !userData.last_name.trim()) {
            api.open({
                description: translation['last_name_required'],
            });
            return;
        } else if (
            !userData.email ||
            !userData.email.trim() ||
            !validateEmail(userData.email)
        ) {
            api.open({
                description: translation['enter_valid_email'],
            });
            return;
        } else if (!userData.password || !userData.password.trim()) {
            api.open({
                description: translation['password_required'],
            });
            return;
            // if (userData.password && !characterValidate(userData.password)) {
            //     api.open({
            //         description: 'Password is required!',
            //     });
            //     // 'Password must contain one upper character,one lower character, one special character & one number'
            //     return;
            // } else
        } else if (
            !userData.password_confirmation ||
            !userData.password_confirmation.trim()
        ) {
            api.open({
                description: translation['confirm_password_required'],
            });
            return;
        } else if (
            userData.password.toLowerCase() !==
            userData.password_confirmation.toLowerCase()
        ) {
            api.open({
                description: translation['mismatch_password'],
            });
            return;
        }
        const payload = {
            source: 'delivery-doha',
            device_type: 'web',
            device_token: 'test_abcdefghji',
            hotel_enc_id: userData.hotel_enc_id,
            password: userData.password,
            password_confirmation: userData.password_confirmation,
            mobile: userData.mobile,
            email: userData.email,
            first_name: userData.first_name,
            last_name: userData.last_name,
            mobile_country_code: +userData.mobile_country_code,
            nationality: userData.nationality,
        };
        registerUserClickHandler(payload);
    };

    const changePasswordBtn = () => {
        if (!userData.mobile || !userData.mobile.trim()) {
            api.open({
                description: translation['mobile_no_required'],
            });
            return;
        } else if (!old_password || !old_password.trim()) {
            api.open({
                description: translation['old_password_required'],
            });
            return;
        } else if (!userData.password || !userData.password.trim()) {
            api.open({
                description: translation['new_password_required'],
            });
            return;
        } else if (userData.password && !characterValidate(userData.password)) {
            api.open({
                description:
                    'New Password must contain one upper character,one lower character, one special character & one number',
            });
            return;
        } else if (
            !userData.password_confirmation ||
            !userData.password_confirmation.trim()
        ) {
            api.open({
                description: translation['confirm_new_password_required'],
            });
            return;
        } else if (
            userData.password_confirmation &&
            !characterValidate(userData.password_confirmation)
        ) {
            api.open({
                description: translation['mismatch_password'],
            });
            return;
        } else if (
            userData.password.toLowerCase() !==
            userData.password_confirmation.toLowerCase()
        ) {
            api.open({
                description: translation['mismatch_password'],
            });
            return;
        }
        const payload = {
            username: userData.mobile,
            old_password: old_password,
            password: userData.password,
            password_confirmation: userData.password_confirmation,
        };
        changePasswordClickHandler(payload);
    };
    const forgetPasswordBtn = () => {
        if (!userData.mobile || !userData.mobile.trim()) {
            api.open({
                description: translation['mobile_no_required'],
            });
            return;
        }
        const payload = {
            username: userData.mobile,
        };
        forgetPasswordClickHandler(payload);
    };
    const pencilEditClickHandler = () => {
        setCurrentView('mobile');
        setError('');
        resetUserData();
    };
    const resetUserData = () => {
        setOldPassword('');
        setUserData((prevState) => ({
            ...prevState,
            mobile: '',
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            password_confirmation: '',
            mobile_country_code: '974',
        }));
    };

    return (
        <div
            className='relative flex flex-1 justify-center flex-col items-center'
            style={{ backgroundColor: '#f8f7f4', minHeight: '100vh' }}
        >
            {contextHolder}
            <div
                className='fixed flex top-4 left-4 bg-gray-50 rounded-full p-2 shadow-lg z-9'
                style={{ backgroundColor: themeColors.primaryColor }}
                onClick={() => navigate(-1)}
            >
                <ArrowLeftOutlined style={{ color: '#fff' }} />
            </div>

            <div
                className='fixed -left-28 -bottom-28 w-72 h-72 bg-white/20 rounded-full justify-center items-center'
                style={{ backgroundColor: themeColors.bgColor(0.3) }}
            ></div>
            <div
                className='fixed -left-24 -bottom-24 w-60 h-60 bg-white/20 rounded-full justify-center items-center'
                style={{ backgroundColor: themeColors.bgColor(0.5) }}
            ></div>
            <div className='flex mb-6 justify-center items-center'>
                <img
                    style={{
                        width: 200,
                        height: 100,
                        objectFit: 'contain',
                    }}
                    src={LogoBlack}
                    alt='logo'
                />
            </div>
            <div className=' flex justify-center items-center flex-col w-full px-4 flex-col'>
                {currentView === 'mobile' && (
                    <div className='flex flex-col w-full justify-center items-center'>
                        <div className='mb-2'>
                            <div className='text-sm '>
                                {translation['enter_your_mobile_number']}
                            </div>
                        </div>
                        <div className='mb-2 flex flex-row gap-2 w-full'>
                            <Input
                                className='h-12 border rounded-xl border-gray-300 p-3 w-10 bg-gray-100 text-center'
                                placeholder='Country code'
                                onChange={(e) =>
                                    inputChangeHandler(
                                        'mobile_country_code',
                                        e.target.value
                                    )
                                }
                                value={`+${userData.mobile_country_code}`}
                                maxLength={4}
                                autoFocus
                                style={{ width: 70 }}
                                disabled={true}
                            />
                            <Input
                                className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                                type='number'
                                pattern='[0-9]*'
                                inputMode='numeric'
                                placeholder={translation['enter_mobile_number']}
                                onChange={(e) =>
                                    inputChangeHandler('mobile', e.target.value)
                                }
                                value={userData.mobile}
                                maxLength={12}
                                autoFocus
                            />
                        </div>
                        <Button
                            className='flex mt-2 flex-row bg-gray-50 p-3 px-10 rounded-full shadow w-36 h-11 justify-center items-center'
                            style={{
                                backgroundColor: themeColors.primaryColor,
                            }}
                            onClick={continueBtn}
                            disabled={loading}
                        >
                            {loading ? (
                                <LoadingOutlined style={{ color: '#fff' }} />
                            ) : (
                                <div className='text-sm font-medium text-white'>
                                    {translation['continue']}
                                </div>
                            )}
                        </Button>
                    </div>
                )}
                {currentView === 'login' && (
                    <Login
                        userData={userData}
                        inputChangeHandler={inputChangeHandler}
                        loginBtn={loginBtn}
                        loading={loading}
                        setCurrentView={setCurrentView}
                        hidePassword={hidePassword}
                        setHidePassword={setHidePassword}
                        translation={translation}
                    />
                )}
                {currentView === 'register' && (
                    <Register
                        userData={userData}
                        inputChangeHandler={inputChangeHandler}
                        registerBtn={registerBtn}
                        loading={loading}
                        setCurrentView={setCurrentView}
                        pencilEditClickHandler={pencilEditClickHandler}
                        hidePassword={hidePassword}
                        setHidePassword={setHidePassword}
                        translation={translation}
                    />
                )}
                {currentView === 'forgetPassword' && (
                    <ForgetPassword
                        userData={userData}
                        inputChangeHandler={inputChangeHandler}
                        loading={loading}
                        setCurrentView={setCurrentView}
                        forgetPasswordBtn={forgetPasswordBtn}
                        translation={translation}
                    />
                )}
                {currentView === 'changePassword' && (
                    <ChangePassword
                        userData={userData}
                        inputChangeHandler={inputChangeHandler}
                        changePasswordBtn={changePasswordBtn}
                        loading={loading}
                        setCurrentView={setCurrentView}
                        old_password={old_password}
                        setOldPassword={setOldPassword}
                        hidePassword={hidePassword}
                        setHidePassword={setHidePassword}
                        translation={translation}
                    />
                )}
                {error && (
                    <div>
                        <div className='text-xs text-red-500'>{error}</div>
                    </div>
                )}
                {currentView !== 'forgetPassword' && (
                    <div className='flex-row mt-3 items-center justify-end ml-auto'>
                        <Button
                            className='flex-row rounded-full text-sm font-medium	text-white p-0 border-none'
                            onClick={() => {
                                setCurrentView('forgetPassword');
                                resetUserData();
                            }}
                            style={{
                                color: themeColors.primaryColor,
                            }}
                        >
                            {translation['forget_password']}
                        </Button>
                    </div>
                )}
                {currentView !== 'changePassword' && (
                    <div className='items-end justify-end flex-col ml-auto'>
                        <div className='relative flex flex-col justify-center items-center my-2 w-32'>
                            <div
                                className='absolute w-full bg-gray-300 inset-y-1/2 top-0'
                                style={{
                                    top: '50%',
                                    bottom: '50%',
                                    height: '1px',
                                }}
                            ></div>
                            <div
                                className='relative px-4'
                                style={{ backgroundColor: '#f8f7f4' }}
                            >
                                <div className='text-sm font-semibold text-gray-300'>
                                    {translation['or']}
                                </div>
                            </div>
                        </div>
                        <Button
                            className='flex-row rounded-full  text-sm font-medium	text-white p-0 border-none'
                            style={{ color: themeColors.primaryColor }}
                            onClick={() => {
                                setCurrentView('changePassword');
                                resetUserData();
                            }}
                        >
                            <div>{translation['change_password']}</div>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginScreen;
