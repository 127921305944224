import React from 'react';
import { themeColors } from '../theme';
import { AlignRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function SplashScreen() {
    const navigate = useNavigate();

    let timerID = null;
    // useEffect(() => {
    //     ring1Padding.value = 0;
    //     ring2Padding.value = 0;
    //     let springID1 = setTimeout(
    //         () => (ring1Padding.value = withSpring(ring1Padding.value + 288)),
    //         100
    //     );
    //     let springID2 = setTimeout(
    //         () => (ring2Padding.value = withSpring(ring2Padding.value + 208)),
    //         300
    //     );
    //     timerID = setTimeout(() => {
    //         navigation.dispatch(StackActions.replace('Home'));
    //     }, 2500);
    //     return () => {
    //         clearTimeout(springID1);
    //         clearTimeout(springID2);
    //         clearTimeout(timerID);
    //     };
    // }, []);
    const navigateToHome = () => {
        clearTimeout(timerID);
        navigate('/home');
    };
    return (
        <div 
            className='flex flex-1 relative justify-center items-center px-4'
            style={{ backgroundColor: themeColors.primaryColor }}
        >
            
            <div className='flex flex-col justify-center items-center space-y-4'>
                <div
                    className='bg-white/20 rounded-full justify-center items-center'
                    // style={{ width: ring1Padding, height: ring1Padding }}
                >
                    <div
                        className='bg-white/20 rounded-full justify-center items-center'
                        // style={{ width: ring2Padding, height: ring2Padding }}
                    >
                        <img
                            style={{
                                width: 200,
                                height: 100,
                                objectFit: 'contain',
                            }}
                            alt='splash-logo'
                            src={'https://master-vserve.s3.ap-south-1.amazonaws.com/property/1694454283-home_logo-Pure%20white.png'}
                        />
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center space-y-3'>
                    <div  className='text-white text-xl font-bold '>
                        Welcome to W2GO
                    </div>
                    <div  className='text-white mb-4'>
                        We're commited to deliver healty food at your doorstep!
                    </div>
                    <div
                        className='flex-row bg-gray-50 p-4 rounded-full shadow w-12 h-12 justify-center items-center'
                        onClick={() => navigateToHome()}
                    >
                    <AlignRightOutlined/>
                    </div>
                </div>
            </div>
        </div>
    );
}
