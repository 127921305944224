import React, { useEffect, useState } from 'react';
import { themeColors } from '../theme';
import { getOrderDetails } from '../services/apiService';
import { renderCurrency } from '../services/commonHelper';
import { useLanguage } from '../context/LanguageContext';
import {
    ArrowLeftOutlined,
    CheckCircleOutlined,
    CloseOutlined,
    EnvironmentOutlined,
    PhoneOutlined,
    WhatsAppOutlined,
} from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Drawer, Skeleton } from 'antd';
import RepeatLastOrder from '../components/RepeatLastOrder';
const totalforUserHistory = (order_menu_items = []) => {
    let totalTaxes = 0;
    let itemTotal = 0;
    let formatted_order_menu_items = order_menu_items?.map((item) => {
        let itemPrice = 0;
        let addonPrice = 0;

        if (item.price > 0) {
            itemPrice += item.price * item.quantity;
        }

        // adding selected addons list (also adding price and taxes)
        const updateTempSubItem = (addOns) => {
            addOns?.forEach((addOn) => {
                if (addOn.price > 0) {
                    const totalAddonPrice = addOn.price * item.quantity;
                    addonPrice += totalAddonPrice;
                }
            });
        };
        updateTempSubItem(item.order_addons);
        item['itemPriceWithCustomization'] = itemPrice + addonPrice; // updating item price
        itemTotal += item.itemPriceWithCustomization; // updating total price
        return item;
    });
    let itemsTotal = itemTotal + totalTaxes;
    return { order_menu_items: formatted_order_menu_items, itemsTotal };
};

const TrackOrderScreen = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [repeatOrderPopupOpen, setRepeatOrderPopupOpen] = useState(false);

    const [total, setTotal] = useState(0);
    const { lang, translation } = useLanguage();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const orderID = searchParams.get('orderID');

    useEffect(() => {
        if (orderID) {
            fetchOrderDetails(orderID);
        }
    }, [orderID]);

    const fetchOrderDetails = async (id) => {
        try {
            setLoading(true);
            const response = await getOrderDetails(id, lang);
            if (response.status === 200) {
                const data = response.data.data || {};
                const { order_menu_items, itemsTotal } = totalforUserHistory(
                    data.order_menu_items || []
                );
                debugger;
                data.order_menu_items = order_menu_items;
                setTotal(itemsTotal);
                setOrderDetails(data);
            }
        } catch (error) {
            setError('Something went wrong! Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const openRepeatOrderPopup = () => {
        setRepeatOrderPopupOpen(true);
    };

    const renderFinalPrice = (item) => {
        let value = total;
        if (orderDetails.order_detail?.service_type === 'delivery') {
            value += orderDetails.order_detail?.amount_details?.delivery_charge;
        }
        if (orderDetails.order_detail?.amount_details?.tax > 0) {
            value += orderDetails.order_detail?.amount_details?.tax;
        }
        return renderCurrency(value);
    };

    console.log(orderDetails.order_menu_items);

    return (
        <div
            className='flex flex-1 flex-col'
            style={{ backgroundColor: '#f8f7f4', minHeight: '100vh' }}
        >
            <div className='flex sticky top-0 left-4 w-full flex-row justify-between items-center bg-white z-10 px-4 py-2 shadow'>
                <div
                    className='flex bg-gray-50 rounded-full p-2 shadow-lg'
                    style={{ backgroundColor: themeColors.primaryColor }}
                    onClick={() => navigate(-1)}
                >
                    <ArrowLeftOutlined style={{ color: '#fff' }} />
                </div>
                <div className='text-black text-lg font-medium ml-8'>
                    {translation['track_order']}
                </div>
                <Button
                    className='flex rounded-full justify-center items-center px-3 py-0.5 text-white text-xs'
                    style={{
                        backgroundColor: themeColors.primaryColor,
                    }}
                    onClick={() => openRepeatOrderPopup()}
                >
                    {translation['order_again']}
                </Button>
                {/* <Button
                    className='flex h-7 rounded-full justify-center items-center px-3 py-0.5'
                    style={{ backgroundColor: themeColors.primaryColor }}
                    onClick={() => navigate(-1)}
                >
                    <div className=' text-white text-xs'>
                        {translation['close']}
                    </div>
                </Button> */}
            </div>
            {loading ? (
                <div className='flex flex-col flex-1 gap-2 justify-start items-center my-4 px-4'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
            ) : error ? (
                <div className='flex flex-col flex-1 gap-2 justify-center items-center  my-4 px-4'>
                    <div
                        className='text-xs text-center '
                        style={{ color: 'red' }}
                    >
                        {translation['something_went_wrong']}
                    </div>
                </div>
            ) : orderDetails && Object.keys(orderDetails).length > 0 ? (
                <div className='flex flex-1 flex-col justify-start items-start pb-8 pt-4'>
                    <div className='flex justify-between w-full px-4 mb-4'>
                        <div className='text-black text-xl font-medium'>
                            {translation['order_info']}
                        </div>
                    </div>
                    <div className='flex flex-col w-full flex-1 relative pt-2 px-4  pb-0'>
                        <div className=' flex flex-col  gap-y-1'>
                            <div className='text-sm text-gray-700 font-normal'>
                                {translation['order_date']}:{' '}
                                {new Date(
                                    orderDetails.order_detail?.created_at
                                ).toLocaleDateString()}{' '}
                                |{' '}
                                {new Date(
                                    orderDetails.order_detail?.created_at
                                ).toLocaleTimeString()}
                            </div>
                            <div className='flex flex-row justify-between '>
                                <div className='text-sm text-gray-700 font-semibold'>
                                    {translation['order_id']} #
                                    {orderDetails.order_detail?.order_id}
                                </div>
                                <div className='text-black text-md text-left font-semibold'>
                                    {translation['amount']}:{' '}
                                    {renderFinalPrice(orderDetails)}
                                </div>
                            </div>
                        </div>
                        <div className=' flex w-full gap-y-3 my-2 flex-col'>
                            <div className=' flex flex-row  gap-x-1'>
                                <div className='flex mr-3 mt-1'>
                                    <div
                                        className=' flex-row rounded-full shadow w-6 h-6 flex justify-center items-center'
                                        style={{
                                            backgroundColor: 'green',
                                        }}
                                    >
                                        <CheckCircleOutlined
                                            style={{
                                                color: '#fff',
                                            }}
                                        />
                                    </div>
                                </div>
                                {orderDetails.order_detail?.cleared_at ? (
                                    <div className='flex flex-col'>
                                        <div className='text-md text-gray-700 font-semibold'>
                                            {translation['']}
                                            {orderDetails.order_detail
                                                ?.service_type === 'delivery'
                                                ? translation['order_delivered']
                                                : translation[
                                                      'order_delivered_takeaway'
                                                  ]}
                                        </div>
                                        <div className='text-xs text-gray-400 font-normal '>
                                            {translation['order_delivered_msg']}
                                        </div>
                                    </div>
                                ) : orderDetails.order_detail?.dispatched_at ? (
                                    <div className='flex flex-col'>
                                        <div className='text-md text-gray-700 font-semibold'>
                                            {orderDetails.order_detail
                                                ?.service_type === 'delivery'
                                                ? translation['on_the_way']
                                                : translation[
                                                      'on_the_way_takeaway'
                                                  ]}
                                        </div>
                                        <div className='text-xs text-gray-400 font-normal '>
                                            {orderDetails.order_detail
                                                ?.service_type === 'delivery'
                                                ? translation['on_the_way_msg']
                                                : translation[
                                                      'on_the_way_msg_takeaway'
                                                  ]}
                                        </div>
                                    </div>
                                ) : orderDetails.order_detail?.accepted_at ? (
                                    <div className='flex flex-col'>
                                        <div className='text-md text-gray-700 font-semibold'>
                                            {translation['order_accepted']}
                                        </div>
                                        <div className='text-xs text-gray-400 font-normal '>
                                            {orderDetails.order_detail
                                                ?.service_type === 'delivery'
                                                ? translation[
                                                      'order_accepted_msg'
                                                  ]
                                                : translation[
                                                      'order_accepted_msg_takeaway'
                                                  ]}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex flex-col'>
                                        <div className='text-md text-gray-700 font-semibold'>
                                            {translation['order_placed']}
                                        </div>
                                        <div className='text-xs text-gray-400 font-normal '>
                                            {translation['order_placed_msg']}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <div className='flex flex-row gap-y-1 justify-between w-full'>
                            <div className='flex flex-col gap-y-1 '>
                                <div  className='text-sm font-semibold'>
                                    Order Placed
                                </div>
                                <div  className='text-xs text-gray-700 '>
                                    We have received your order
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-1 '>
                                <div  className='text-xs text-gray-700 '>
                                    {new Date().toLocaleTimeString()}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row gap-y-1 justify-between w-full'>
                            <div className='flex flex-col gap-y-1 '>
                                <div  className='text-sm font-semibold'>
                                    Order Placed
                                </div>
                                <div  className='text-xs text-gray-700 '>
                                    We have received your order
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-1 '>
                                <div  className='text-xs text-gray-700 '>
                                    {new Date().toLocaleTimeString()}
                                </div>
                            </div>
                        </div> */}
                        </div>

                        <div className='flex w-full gap-y-3 my-2 flex-col'>
                            <div className='text-md text-gray-700 font-semibold'>
                                {translation['order_summary']}:
                            </div>
                            {orderDetails.order_menu_items?.map(
                                (orderItem, index) => (
                                    <div
                                        className='flex flex-row flex-1 justify-between pl-0'
                                        key={orderItem.id}
                                    >
                                        <div className='flex flex-1 flex-col justify-start items-start mr-10'>
                                            <div className='flex flex-1 flex-row justify-start items-start mr-10'>
                                                {/* <div
                                                    className='flex rounded-full justify-center items-center mr-2 w-5 h-5 text-xs'
                                                    style={{
                                                        borderWidth: 1,
                                                        minWidth: '1.25rem',
                                                        borderColor:
                                                            themeColors.primaryColor,
                                                    }}
                                                >
                                                    {index + 1}
                                                </div> */}
                                                {/* <div className=' flex flex-1 flex-wrap'> */}
                                                <div className=' text-gray-500 text-xs'>
                                                    {`${orderItem.quantity} x `}
                                                    {orderItem.item_name}
                                                </div>
                                            </div>
                                            <div className='flex flex-1 flex-col justify-start items-start pl-8'>
                                                {orderItem.order_addons.length >
                                                    0 &&
                                                    orderItem.order_addons.map(
                                                        (add) => (
                                                            <div
                                                                className='addon__item mb-1'
                                                                key={add.id}
                                                            >
                                                                <div className=' text-gray-500 text-xs font-semibold'>
                                                                    {
                                                                        add.item
                                                                            .item_subaddon
                                                                            .name
                                                                    }
                                                                    :
                                                                </div>
                                                                <div className=' text-gray-500 text-xs pl-3'>
                                                                    {
                                                                        add.item_name
                                                                    }
                                                                    {/* {add.price >
                                                                        0 &&
                                                                        `: ${
                                                                            add.quantity
                                                                        } * ${renderCurrency(
                                                                            add.price
                                                                        )}`} */}
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div className='text-green-800 text-xs'>
                                                {orderItem?.description &&
                                                    `${translation['instructions']} - ${orderItem.description}`}
                                            </div>
                                        </div>
                                        {orderItem.itemPriceWithCustomization >
                                            0 && (
                                            <div className='text-gray-500 text-xs'>
                                                {renderCurrency(
                                                    orderItem.itemPriceWithCustomization
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )
                            )}

                            <div
                                className='flex bg-white flex-1 border border-gray-200 p-2 px-1 flex-col justify-between'
                                style={{
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                }}
                            >
                                <div className='flex flex-row justify-between w-full mb-1'>
                                    <div className='flex text-xs'>
                                        {translation['subtotal']}:
                                    </div>
                                    <div className='flex text-xs'>
                                        {renderCurrency(total)}
                                    </div>
                                </div>
                                {orderDetails.order_detail?.amount_details
                                    ?.tax > 0 && (
                                    <div className='flex flex-row justify-between w-full mb-1'>
                                        <div className='flex text-xs'>Tax</div>
                                        <div className='flex text-xs'>
                                            {renderCurrency(
                                                orderDetails.order_detail
                                                    ?.amount_details?.tax
                                            )}
                                        </div>
                                    </div>
                                )}
                                {orderDetails.order_detail?.service_type ===
                                    'delivery' && (
                                    <div className='flex flex-row justify-between w-full'>
                                        <div className='flex text-xs'>
                                            {translation['delivery_charges']}:
                                        </div>
                                        <div className='flex text-xs'>
                                            {renderCurrency(
                                                orderDetails.order_detail
                                                    ?.amount_details
                                                    ?.delivery_charge
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className='flex flex-row justify-between w-full mt-1'>
                                    <div className='flex text-xs'>
                                        {translation['payment_method']}:
                                    </div>
                                    <div className='flex text-xs'>
                                        {orderDetails.payment_type === 'CASH'
                                            ? translation[
                                                  `by_${
                                                      orderDetails.order_detail
                                                          ?.service_type ===
                                                      'delivery'
                                                          ? 'cash'
                                                          : 'cash_pickup'
                                                  }`
                                              ]
                                            : translation[
                                                  `by_${
                                                      orderDetails.order_detail
                                                          ?.service_type ===
                                                      'delivery'
                                                          ? 'edc_machine'
                                                          : 'edc_machine_pickup'
                                                  }`
                                              ]}
                                    </div>
                                </div>
                                <div className='flex flex-row justify-between w-full mt-3'>
                                    <div className='flex font-medium'>
                                        {translation['order_total']}:
                                    </div>
                                    <div className='flex font-medium'>
                                        {renderFinalPrice(orderDetails)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full my-2'>
                            <div className='text-md text-gray-700 font-semibold'>
                                {translation['cooking_instructions']}:
                            </div>
                            <div className='pl-2 pt-0.5 text-xs text-gray-400 font-normal mt-1'>
                                {orderDetails.description || '--'}
                            </div>
                        </div>
                        {orderDetails.order_detail?.service_type ===
                            'delivery' && (
                            <div className='flex flex-row gap-x-1 mb-3 mt-3 pb-10'>
                                <div className='flex mr-3 mt-2'>
                                    <EnvironmentOutlined
                                        style={{
                                            color: themeColors.primaryColor,
                                        }}
                                    />
                                </div>
                                <div className='flex flex-1 flex-col'>
                                    <div className='text-md text-gray-700 font-semibold'>
                                        {translation['address']}:
                                    </div>
                                    <div className='text-xs text-gray-400 font-normal flex-wrap'>
                                        {orderDetails.order_address?.[0]?.address
                                            ?.split('|')
                                            ?.join(' ')}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <div className='flex my-8 justify-center items-center'>
                            <img
                                style={{
                                    width: 200,
                                    height: 100,
                                    objectFit: 'contain',
                                }}
                                source={require('../assets/logoblack.png')}
                            />
                        </div> */}
                    </div>
                    <div className='fixed bottom-5 flex flex-row justify-end right-0 gap-2 px-4'>
                        {orderDetails?.outlet?.mobile && (
                            <a
                                className=' rounded-full p-2 w-10 h-10 flex justify-center items-center'
                                style={{ backgroundColor: '#25d366' }}
                                href={`tel:+${
                                    orderDetails?.outlet?.country?.phone_code ||
                                    '974'
                                }${orderDetails?.outlet?.mobile}`}
                            >
                                <PhoneOutlined
                                    style={{
                                        color: '#fff',
                                        fontSize: '1.5rem',
                                        transform: 'rotate(90deg)',
                                    }}
                                />
                            </a>
                        )}
                        {orderDetails?.outlet?.contact_no && (
                            <a
                                className=' rounded-full p-2 w-10 h-10 flex justify-center items-center'
                                style={{ backgroundColor: '#25d366' }}
                                href={`https://api.whatsapp.com/send?phone=${
                                    orderDetails?.outlet?.country?.phone_code ||
                                    '974'
                                }${
                                    orderDetails?.outlet?.contact_no
                                }&amp;text=Hi`}
                            >
                                <WhatsAppOutlined
                                    style={{
                                        color: '#fff',
                                        fontSize: '1.5rem',
                                    }}
                                />
                            </a>
                        )}
                    </div>
                </div>
            ) : (
                <div className='flex flex-1 flex-col justify-center items-center'>
                    <div>No Details found!</div>
                </div>
            )}
            <Drawer
                title={translation['order_again']}
                placement={'bottom'}
                closable={false}
                onClose={() => setRepeatOrderPopupOpen(false)}
                open={repeatOrderPopupOpen}
                size='default'
                rootClassName='repeat-order'
                height={'550px'}
                extra={
                    <Button
                        className='flex justify-center items-center'
                        onClick={() => setRepeatOrderPopupOpen(false)}
                    >
                        <CloseOutlined />
                    </Button>
                }
            >
                <RepeatLastOrder
                    orderDetails={orderDetails}
                    setRepeatOrderPopupOpen={setRepeatOrderPopupOpen}
                    repeatOrderPopupOpen={repeatOrderPopupOpen}
                    orderID={orderID}
                    serviceType={orderDetails.order_detail?.service_type}
                />
            </Drawer>
        </div>
    );
};

export default TrackOrderScreen;
