
import React, { createContext, useContext, useEffect } from 'react';
import { directory } from '../services/Typecode';
import { useHotel } from './HotelContext';

const LanguageContext = createContext({});
export const useLanguage = () => useContext(LanguageContext);

const languageList = [
    {
        enabled: 1,
        id: 6,
        lang_code: 'en',
        lang_name: 'English',
        writing_system: 'ltr',
    },
];
const LanguageContextProvider = ({ children }) => {
    const [lang, setLang] = React.useState('en');
    const [langList, setLangList] = React.useState(languageList);
    const [translation, setTranslation] = React.useState({});
    const { hotelDetails } = useHotel();

    useEffect(() => {
        if (
            hotelDetails.hotel_languages &&
            hotelDetails.hotel_languages.length
        ) {
            setLangList(hotelDetails.hotel_languages);
        }
    }, [hotelDetails, hotelDetails.hotel_languages]);

    React.useEffect(() => {
        let code = lang ?? 'en';
        const getTranslation = directory[code] || {};
        setTranslation(getTranslation);
    }, [lang]);
    React.useEffect(() => {
        const updateLanguage = async () => {
            try {
                const response = await localStorage.getItem('lang');
                setLang(response || 'en');
            } catch (error) {}
        };

        updateLanguage();
    }, []);

    // const renderText = (key) => {
    //     const value = translation[key]
    //     return value;
    // };
    const updateLocalStorage = async (lang) => {
        try {
            await localStorage.setItem('lang', lang);
        } catch (error) {}
    };
    const changeLanguage = (newlang) => {
        setLang(newlang);
        updateLocalStorage(newlang);
    };

    const value = {
        changeLanguage,
        lang,
        langList,
        translation,
    };
    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageContextProvider;
