import React from 'react';
import { themeColors } from '../theme';
import SubCategoryCard from './SubCategoryCard';
import ItemCard from './ItemCard';
import HTMLContent from './HTMLContent';

const CategoryCard = (props) => {
    const { category, updateItemQuantity,isViewOnly } = props;

    return (
        <div
            className={
                'w-full flex flex-col mb-4 flex-1 justify-start items-start px-4'
            }
            id={`category-${category.id}`}
        >
            {category.name && (
                <div 
                    className='-ml-4 w-64 relative mb-4'
                    style={{
                        backgroundColor: themeColors.primaryColor,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                >
                    <div 
                        className='absolute w-0'
                        style={{
                            borderWidth: 16,
                            borderStyle: 'solid',
                            borderTopColor: 'transparent',
                            borderRightColor: 'transparent',
                            borderBottomColor: themeColors.primaryColor,
                            borderLeftColor: themeColors.primaryColor,
                            right: -32,
                            bottom: 0,
                        }}
                    ></div>
                    <div 
                        className='absolute w-0'
                        style={{
                            borderWidth: 16,
                            borderStyle: 'solid',
                            borderTopColor: themeColors.primaryColor,
                            borderRightColor: 'transparent',
                            borderBottomColor: 'transparent',
                            borderLeftColor: themeColors.primaryColor,
                            right: -32,
                        }}
                    ></div>

                    <div  className='w-full text-sm font-normal text-white p-2'>
                        {category.name}
                    </div>
                </div>
            )}
            {category.image && (
                <div className='flex w-full h-48 mt-2 '>
                    <img
                        className={'rounded-2xl w-full h-full'}
                        src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-categories/${category.image}`}
                        alt='category'
                    />
                </div>
            )}
            {category.description && (
                <div className=' w-full flex-col ml-2 items-start pt-0.5 justify-between flex-1 '>
                    <div  className='text-xs text-gray-700 '>
                        <HTMLContent html={category.description} />
                    </div>
                </div>
            )}
            {category.sub_categories.map(
                (subcategory) =>
                    Boolean(subcategory.enabled) && (
                        <SubCategoryCard
                            key={subcategory.id}
                            subcategory={subcategory}
                            updateItemQuantity={updateItemQuantity}
                            isViewOnly={isViewOnly}
                        />
                    )
            )}
            {category.without_sub_category_items.map(
                (item) =>
                    Boolean(item.enabled) && (
                        <ItemCard key={item.id} item={item} updateItemQuantity={updateItemQuantity} isViewOnly={isViewOnly}/>
                    )
            )}
        </div>
    );
};

export default React.memo(CategoryCard);
