import React from 'react';
import ItemCard from './ItemCard';
import HTMLContent from './HTMLContent';

function SubCategoryCard(props) {
    const { subcategory, updateItemQuantity,isViewOnly } = props;
    return (
        <div className='flex flex-col w-full'>
            <div className='relative flex flex-col justify-center items-center my-2'>
                <div 
                    className='absolute w-full bg-slate-300 inset-y-1/2'
                    style={{
                        left: 0,
                        height: 1,
                    }}
                ></div>
                <div className='relative bg-white px-4'>
                    <div  className='text-sm text-black font-semibold '>
                        {subcategory.name}
                    </div>
                </div>
            </div>
            {subcategory.description && (
                <div  className='text-xs text-gray-700 mb-2'>
                    <HTMLContent html={subcategory.description} />
                </div>
            )}

            {subcategory.items?.map(
                (item) =>
                    Boolean(item.enabled) && (
                        <ItemCard
                            key={item.id}
                            item={item}
                            updateItemQuantity={updateItemQuantity}
                            isViewOnly={isViewOnly}
                        />
                    )
            )}
        </div>
    );
}

export default React.memo(SubCategoryCard);
