import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { themeColors } from '../theme';
import { removeData } from '../services/storageService';
import { CART_ITEMS } from '../services/Typecode';
import { useLanguage } from '../context/LanguageContext';
import { AlignRightOutlined } from '@ant-design/icons';
import Thankyou from '../assets/thankyou.jpg';
import { Button } from 'antd';
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaLinkedin,
    FaSnapchatGhost,
} from 'react-icons/fa';

const ThankYouScreen = () => {
    const { translation } = useLanguage();
    const [searchParams] = useSearchParams();
    const orderID = searchParams.get('orderID');
    const navigate = useNavigate();

    useEffect(() => {
        removeData(CART_ITEMS);
    }, []);
    return (
        <div
            className='relative flex flex-1  justify-center px-6 flex-col'
            style={{ backgroundColor: '#0a001e', minHeight: '100vh' }}
        >
            {/* <div
                className='absolute top-14 left-4 bg-gray-50 rounded-full p-2 shadow-lg'
                style={{ backgroundColor: themeColors.primaryColor }}
                onClick={() => navigate(-1)}
            >
                <Icon.ArrowLeft
                    width={16}
                    height={16}
                    strokeWidth={3}
                    stroke={'white'}
                />
            </div> */}
            {/* <div 
                className='absolute -left-28 -bottom-28 w-72 h-72 bg-white/20 rounded-full justify-center items-center'
                style={{ backgroundColor: themeColors.bgColor(0.3) }}
            ></div>
            <div 
                className='absolute -left-24 -bottom-24 w-60 h-60 bg-white/20 rounded-full justify-center items-center'
                style={{ backgroundColor: themeColors.bgColor(0.5) }}
            ></div> */}
            <div className='flex w-full flex-col'>
                <div className='flex justify-center items-center mb-4'>
                    <img
                        className={'w-64 h-64 rounded-[10px] object-cover'}
                        src={Thankyou}
                        alt='thank-you'
                    />
                </div>
                {/* <div className='flex mb-4 justify-center items-center'>
                    <AlignRightOutlined />
                </div> */}
                <div className='mb-5'>
                    <div className='text-md text-white px-2 text-center'>
                        {translation['order_number']} #{orderID}
                    </div>
                    <div className='text-xs  text-white px-4 text-center mb-4 mt-2'>
                        {translation['thank_you_for_your_order']}
                    </div>
                    <div className='text-sm font-semibold text-white px-4 text-center'>
                        {translation['stay_up_to_date']}
                    </div>
                    <div className='text-sm font-semibold text-white px-4 text-center mb-2'>
                        <a
                            href='https://linktr.ee/wdoha'
                            target='_blank'
                            className='underline'
                            rel='noreferrer'
                        >
                            {translation['get_offers']}
                        </a>
                    </div>
                    <div className='text-sm font-semibold text-white px-4 text-center mb-1'>
                        {translation['follow_us']}
                    </div>
                    <div className='flex justify-center gap-4 mt-2'>
                        <a
                            href='https://www.instagram.com/wdoha/'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-pink-600 hover:opacity-75'
                        >
                            <FaInstagram size={24} />
                        </a>
                        <a
                            href='https://www.facebook.com/wdoha'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-blue-600 hover:opacity-75'
                        >
                            <FaFacebook size={24} />
                        </a>
                        <a
                            href='https://www.linkedin.com/company/w-doha-hotel-&-residences/'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-blue-700 hover:opacity-75'
                        >
                            <FaLinkedin size={24} />
                        </a>
                        <a
                            href='https://twitter.com/wdoha?lang=en'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-blue-500 hover:opacity-75'
                        >
                            <FaTwitter size={24} />
                        </a>
                        <a
                            href='https://www.snapchat.com/add/w.doha?invite_id=GlKO6sSV&locale=en_QA&share_id=C1o_oWxzSu6XgOm6mm1auA&sid=fec6e4f2c8994d96b54e9caae2f8f66e'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-yellow-500 hover:opacity-75'
                        >
                            <FaSnapchatGhost size={24} />
                        </a>
                    </div>
                    <div className='text-xs text-white px-4 text-center mt-4'>
                        {translation['see_you_soon']}
                    </div>
                </div>
                {/* <div 
                    className='mb-6 flex flex-row justify-between px-1 py-4 border border-gray-600'
                    style={{ borderLeftWidth: 0, borderRightWidth: 0 }}
                >
                    <div  className='text-sm text-white text-center'>
                        Order Details
                    </div>
                    <div className='m-0'>
                        <Icon.ChevronDown
                            width={20}
                            height={20}
                            stroke={themeColors.primaryColor}
                        />
                    </div>
                </div> */}
                <div className='flexmmt-4 flex-col gap-y-5 '>
                    <div className='flex flex-row justify-center items-center gap-x-2'>
                        <Button
                            className='flex-row text-center rounded-full shadow-lg h-10 justify-center items-center gap-x-2 border-none text-white px-6'
                            style={{
                                backgroundColor: themeColors.primaryColor,
                            }}
                            onClick={() => navigate('/', { replace: true })}
                        >
                            {translation['go_to_home']}
                        </Button>
                        <Button
                            className='flex-row text-center rounded-full shadow-lg h-10 justify-center items-center gap-x-2 px-6 '
                            style={{ color: themeColors.primaryColor }}
                            onClick={() => navigate('/orderhistory')}
                        >
                            {translation['track_Order']}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYouScreen;
