export const USER_LOCATION = 'user_location';
export const TOKEN = 'token';
export const USER_PROPS = 'userProps';
export const LAST_PAGE_VISIT = 'lastPageVisit';
export const CART_ITEMS = 'cartItems';
export const SERVICETYPE = 'serviceType';
export const OUTLET_ID = 'outletID';


export const FLAG_LIST = {
    CA: '🇨🇦',
    US: '🇺🇸',
    EN: '🇺🇸',
    RU: '🇷🇺',
    EG: '🇪🇬',
    ZA: '🇿🇦',
    GR: '🇬🇷',
    NL: '🇳🇱',
    BE: '🇧🇪',
    FR: '🇫🇷',
    ES: '🇪🇸',
    HU: '🇭🇺',
    IT: '🇮🇹',
    RO: '🇷🇴',
    CH: '🇨🇭',
    AT: '🇦🇹',
    GG: '🇬🇬',
    IM: '🇮🇲',
    JE: '🇯🇪',
    UK: '🇬🇧',
    DK: '🇩🇰',
    SE: '🇸🇪',
    NO: '🇳🇴',
    SJ: '🇳🇴',
    PL: '🇵🇱',
    DE: '🇩🇪',
    PE: '🇵🇪',
    MX: '🇲🇽',
    CU: '🇨🇺',
    AR: '🇦🇷',
    BR: '🇧🇷',
    CL: '🇨🇱',
    CO: '🇨🇴',
    VE: '🇻🇪',
    MY: '🇲🇾',
    AU: '🇦🇺',
    CX: '🇨🇽',
    CC: '🇨🇨',
    ID: '🇮🇩',
    PH: '🇵🇭',
    NZ: '🇳🇿',
    SG: '🇸🇬',
    TH: '🇹🇭',
    KZ: '🇰🇿',
    JP: '🗾',
    KR: '🇰🇷',
    VN: '🇻🇳',
    CN: '🇨🇳',
    TR: '🇹🇷',
    IN: '🇮🇳',
    HI: '🇮🇳',
    PK: '🇵🇰',
    AF: '🇦🇫',
    LK: '🇱🇰',
    MM: '🇲🇲',
    IR: '🇮🇷',
    SS: '🇸🇸',
    MA: '🇲🇦',
    DZ: '🇩🇿',
    TN: '🇹🇳',
    LY: '🇱🇾',
    GM: '🇬🇲',
    SN: '🇸🇳',
    MR: '🇲🇷',
    ML: '🇲🇱',
    GN: '🇬🇳',
    CI: '🇨🇮',
    BF: '🇧🇫',
    NE: '🇳🇪',
    TG: '🇹🇬',
    BJ: '🇧🇯',
    MU: '🇲🇺',
    LR: '🇱🇷',
    SL: '🇸🇱',
    GH: '🇬🇭',
    NG: '🇳🇬',
    TD: '🇹🇩',
    CF: '🇨🇫',
    CM: '🇨🇲',
    CV: '🇨🇻',
    ST: '🇸🇹',
    GQ: '🇬🇶',
    GA: '🇬🇦',
    CG: '🇨🇬',
    CD: '🇨🇩',
    AO: '🇦🇴',
    GW: '🇬🇼',
    IO: '🇮🇴',
    SC: '🇸🇨',
    SD: '🇸🇩',
    RW: '🇷🇼',
    ET: '🇪🇹',
    SO: '🇸🇴',
    DJ: '🇩🇯',
    KE: '🇰🇪',
    TZ: '🇹🇿',
    UG: '🇺🇬',
    BI: '🇧🇮',
    MZ: '🇲🇿',
    ZM: '🇿🇲',
    MG: '🇲🇬',
    YT: '🇾🇹',
    RE: '🇷🇪',
    ZW: '🇿🇼',
    NA: '🇳🇦',
    MW: '🇲🇼',
    LS: '🇱🇸',
    BW: '🇧🇼',
    SZ: '🇸🇿',
    KM: '🇰🇲',
    SH: '🇸🇭',
    ER: '🇪🇷',
    AW: '🇦🇼',
    FO: '🇫🇴',
    GL: '🇬🇱',
    KY: '🇰🇾',
    GI: '🇬🇮',
    PT: '🇵🇹',
    LU: '🇱🇺',
    IE: '🇮🇪',
    IS: '🇮🇸',
    AL: '🇦🇱',
    MT: '🇲🇹',
    CY: '🇨🇾',
    AX: '🇦🇽',
    FI: '🇫🇮',
    BG: '🇧🇬',
    LT: '🇱🇹',
    LV: '🇱🇻',
    EE: '🇪🇪',
    MD: '🇲🇩',
    AM: '🇦🇲',
    BY: '🇧🇾',
    AD: '🇦🇩',
    MC: '🇲🇨',
    SM: '🇸🇲',
    VA: '🇻🇦',
    UA: '🇺🇦',
    RS: '🇷🇸',
    ME: '🇲🇪',
    HR: '🇭🇷',
    SI: '🇸🇮',
    BA: '🇧🇦',
    MK: '🇲🇰',
    CZ: '🇨🇿',
    SK: '🇸🇰',
    LI: '🇱🇮',
    FK: '🇫🇰',
    GS: '🇬🇸',
    BZ: '🇧🇿',
    GT: '🇬🇹',
    SV: '🇸🇻',
    HN: '🇭🇳',
    NI: '🇳🇮',
    CR: '🇨🇷',
    PA: '🇵🇦',
    PM: '🇵🇲',
    HT: '🇭🇹',
    GP: '🇬🇵',
    BL: '🇧🇱',
    MF: '🇫🇷',
    BO: '🇧🇴',
    EC: '🇪🇨',
    GF: '🇬🇫',
    GY: '🇬🇾',
    PY: '🇵🇾',
    MQ: '🇲🇶',
    SR: '🇸🇷',
    UY: '🇺🇾',
    AN: '🇳🇱',
    TL: '🇹🇱',
    AQ: '🇦🇶',
    NF: '🇳🇫',
    BN: '🇧🇳',
    NR: '🇳🇷',
    PG: '🇵🇬',
    TO: '🇹🇴',
    SB: '🇸🇧',
    VU: '🇻🇺',
    FJ: '🇫🇯',
    PW: '🇵🇼',
    WF: '🇼🇫',
    CK: '🇨🇰',
    NU: '🇳🇺',
    WS: '🇼🇸',
    KI: '🇰🇮',
    NC: '🇳🇨',
    TV: '🇹🇻',
    PF: '🇵🇫',
    TK: '🇹🇰',
    FM: '🇫🇲',
    MH: '🇲🇭',
    KP: '🇰🇵',
    HK: '🇭🇰',
    MO: '🇲🇴',
    KH: '🇰🇭',
    LA: '🇱🇦',
    PN: '🇵🇳',
    BD: '🇧🇩',
    TW: '🇹🇼',
    MV: '🇲🇻',
    LB: '🇱🇧',
    JO: '🇯🇴',
    SY: '🇸🇾',
    IQ: '🇮🇶',
    KW: '🇰🇼',
    SA: '🇸🇦',
    YE: '🇾🇪',
    OM: '🇴🇲',
    PS: '🇵🇸',
    AE: '🇦🇪',
    IL: '🇮🇱',
    BH: '🇧🇭',
    QA: '🇶🇦',
    BT: '🇧🇹',
    MN: '🇲🇳',
    NP: '🇳🇵',
    TJ: '🇹🇯',
    TM: '🇹🇲',
    AZ: '🇦🇿',
    GE: '🇬🇪',
    KG: '🇰🇬',
    UZ: '🇺🇿',
    BS: '🇧🇸',
    BB: '🇧🇧',
    AI: '🇦🇮',
    AG: '🇦🇬',
    VG: '🇻🇬',
    VI: '🇻🇮',
    BM: '🇧🇲',
    GD: '🇬🇩',
    TC: '🇹🇨',
    MS: '🇲🇸',
    MP: '🇲🇵',
    GU: '🇬🇺',
    AS: '🇦🇸',
    LC: '🇱🇨',
    DM: '🇩🇲',
    VC: '🇻🇨',
    DO: '🇩🇴',
    TT: '🇹🇹',
    KN: '🇰🇳',
    JM: '🇯🇲',
    PR: '🇵🇷',
};
export const NATIONALITY = [
    {
        id: 1,
        label: 'Afghan',
        value: 'Afghan',
    },
    {
        id: 2,
        label: 'Aland Island',
        value: 'Aland Island',
    },
    {
        id: 3,
        label: 'Albanian',
        value: 'Albanian',
    },
    {
        id: 4,
        label: 'Algerian',
        value: 'Algerian',
    },
    {
        id: 5,
        label: 'American Samoan',
        value: 'American Samoan',
    },
    {
        id: 6,
        label: 'Andorran',
        value: 'Andorran',
    },
    {
        id: 7,
        label: 'Angolan',
        value: 'Angolan',
    },
    {
        id: 8,
        label: 'Anguillan',
        value: 'Anguillan',
    },
    {
        id: 9,
        label: 'Antarctic',
        value: 'Antarctic',
    },
    {
        id: 10,
        label: 'Antiguan or Barbudan',
        value: 'Antiguan or Barbudan',
    },
    {
        id: 11,
        label: 'Argentine',
        value: 'Argentine',
    },
    {
        id: 12,
        label: 'Armenian',
        value: 'Armenian',
    },
    {
        id: 13,
        label: 'Aruban',
        value: 'Aruban',
    },
    {
        id: 14,
        label: 'Australian',
        value: 'Australian',
    },
    {
        id: 15,
        label: 'Austrian',
        value: 'Austrian',
    },
    {
        id: 16,
        label: 'Azerbaijani, Azeri',
        value: 'Azerbaijani, Azeri',
    },
    {
        id: 17,
        label: 'Bahamian',
        value: 'Bahamian',
    },
    {
        id: 18,
        label: 'Bahraini',
        value: 'Bahraini',
    },
    {
        id: 19,
        label: 'Bangladeshi',
        value: 'Bangladeshi',
    },
    {
        id: 20,
        label: 'Barbadian',
        value: 'Barbadian',
    },
    {
        id: 21,
        label: 'Belarusian',
        value: 'Belarusian',
    },
    {
        id: 22,
        label: 'Belgian',
        value: 'Belgian',
    },
    {
        id: 23,
        label: 'Belizean',
        value: 'Belizean',
    },
    {
        id: 24,
        label: 'Beninese, Beninois',
        value: 'Beninese, Beninois',
    },
    {
        id: 25,
        label: 'Bermudian, Bermudan',
        value: 'Bermudian, Bermudan',
    },
    {
        id: 26,
        label: 'Bhutanese',
        value: 'Bhutanese',
    },
    {
        id: 27,
        label: 'Bolivian',
        value: 'Bolivian',
    },
    {
        id: 28,
        label: 'Bonaire',
        value: 'Bonaire',
    },
    {
        id: 29,
        label: 'Bosnian or Herzegovinian',
        value: 'Bosnian or Herzegovinian',
    },
    {
        id: 30,
        label: 'Motswana, Botswanan',
        value: 'Motswana, Botswanan',
    },
    {
        id: 31,
        label: 'Bouvet Island',
        value: 'Bouvet Island',
    },
    {
        id: 32,
        label: 'Brazilian',
        value: 'Brazilian',
    },
    {
        id: 33,
        label: 'BIOT',
        value: 'BIOT',
    },
    {
        id: 34,
        label: 'Bruneian',
        value: 'Bruneian',
    },
    {
        id: 35,
        label: 'Bulgarian',
        value: 'Bulgarian',
    },
    {
        id: 36,
        label: 'Burkinabé',
        value: 'Burkinabé',
    },
    {
        id: 37,
        label: 'Burundian',
        value: 'Burundian',
    },
    {
        id: 38,
        label: 'Cabo Verdean',
        value: 'Cabo Verdean',
    },
    {
        id: 39,
        label: 'Cambodian',
        value: 'Cambodian',
    },
    {
        id: 40,
        label: 'Cameroonian',
        value: 'Cameroonian',
    },
    {
        id: 41,
        label: 'Canadian',
        value: 'Canadian',
    },
    {
        id: 42,
        label: 'Caymanian',
        value: 'Caymanian',
    },
    {
        id: 43,
        label: 'Central African',
        value: 'Central African',
    },
    {
        id: 44,
        label: 'Chadian',
        value: 'Chadian',
    },
    {
        id: 45,
        label: 'Chilean',
        value: 'Chilean',
    },
    {
        id: 46,
        label: 'Chinese',
        value: 'Chinese',
    },
    {
        id: 47,
        label: 'Christmas Island',
        value: 'Christmas Island',
    },
    {
        id: 48,
        label: 'Cocos Island',
        value: 'Cocos Island',
    },
    {
        id: 49,
        label: 'Colombian',
        value: 'Colombian',
    },
    {
        id: 50,
        label: 'Comoran, Comorian',
        value: 'Comoran, Comorian',
    },
    {
        id: 51,
        label: 'Congolese',
        value: 'Congolese',
    },
    {
        id: 52,
        label: 'Congolese',
        value: 'Congolese',
    },
    {
        id: 53,
        label: 'Cook Island',
        value: 'Cook Island',
    },
    {
        id: 54,
        label: 'Costa Rican',
        value: 'Costa Rican',
    },
    {
        id: 55,
        label: 'Ivorian',
        value: 'Ivorian',
    },
    {
        id: 56,
        label: 'Croatian',
        value: 'Croatian',
    },
    {
        id: 57,
        label: 'Cuban',
        value: 'Cuban',
    },
    {
        id: 58,
        label: 'Curaçaoan',
        value: 'Curaçaoan',
    },
    {
        id: 59,
        label: 'Cypriot',
        value: 'Cypriot',
    },
    {
        id: 60,
        label: 'Czech',
        value: 'Czech',
    },
    {
        id: 61,
        label: 'Danish',
        value: 'Danish',
    },
    {
        id: 62,
        label: 'Djiboutian',
        value: 'Djiboutian',
    },
    {
        id: 63,
        label: 'Dominican',
        value: 'Dominican',
    },
    {
        id: 64,
        label: 'Dominican',
        value: 'Dominican',
    },
    {
        id: 65,
        label: 'Ecuadorian',
        value: 'Ecuadorian',
    },
    {
        id: 66,
        label: 'Egyptian',
        value: 'Egyptian',
    },
    {
        id: 67,
        label: 'Salvadoran',
        value: 'Salvadoran',
    },
    {
        id: 68,
        label: 'Equatorial Guinean, Equatoguinean',
        value: 'Equatorial Guinean, Equatoguinean',
    },
    {
        id: 69,
        label: 'Eritrean',
        value: 'Eritrean',
    },
    {
        id: 70,
        label: 'Estonian',
        value: 'Estonian',
    },
    {
        id: 71,
        label: 'Ethiopian',
        value: 'Ethiopian',
    },
    {
        id: 72,
        label: 'Falkland Island',
        value: 'Falkland Island',
    },
    {
        id: 73,
        label: 'Faroese',
        value: 'Faroese',
    },
    {
        id: 74,
        label: 'Fijian',
        value: 'Fijian',
    },
    {
        id: 75,
        label: 'Finnish',
        value: 'Finnish',
    },
    {
        id: 76,
        label: 'French',
        value: 'French',
    },
    {
        id: 77,
        label: 'French Guianese',
        value: 'French Guianese',
    },
    {
        id: 78,
        label: 'French Polynesian',
        value: 'French Polynesian',
    },
    {
        id: 79,
        label: 'French Southern Territories',
        value: 'French Southern Territories',
    },
    {
        id: 80,
        label: 'Gabonese',
        value: 'Gabonese',
    },
    {
        id: 81,
        label: 'Gambian',
        value: 'Gambian',
    },
    {
        id: 82,
        label: 'Georgian',
        value: 'Georgian',
    },
    {
        id: 83,
        label: 'German',
        value: 'German',
    },
    {
        id: 84,
        label: 'Ghanaian',
        value: 'Ghanaian',
    },
    {
        id: 85,
        label: 'Gibraltar',
        value: 'Gibraltar',
    },
    {
        id: 86,
        label: 'Greek, Hellenic',
        value: 'Greek, Hellenic',
    },
    {
        id: 87,
        label: 'Greenlandic',
        value: 'Greenlandic',
    },
    {
        id: 88,
        label: 'Grenadian',
        value: 'Grenadian',
    },
    {
        id: 89,
        label: 'Guadeloupe',
        value: 'Guadeloupe',
    },
    {
        id: 90,
        label: 'Guamanian, Guambat',
        value: 'Guamanian, Guambat',
    },
    {
        id: 91,
        label: 'Guatemalan',
        value: 'Guatemalan',
    },
    {
        id: 92,
        label: 'Channel Island',
        value: 'Channel Island',
    },
    {
        id: 93,
        label: 'Guinean',
        value: 'Guinean',
    },
    {
        id: 94,
        label: 'Bissau-Guinean',
        value: 'Bissau-Guinean',
    },
    {
        id: 95,
        label: 'Guyanese',
        value: 'Guyanese',
    },
    {
        id: 96,
        label: 'Haitian',
        value: 'Haitian',
    },
    {
        id: 97,
        label: 'Heard Island or McDonald Islands',
        value: 'Heard Island or McDonald Islands',
    },
    {
        id: 98,
        label: 'Vatican',
        value: 'Vatican',
    },
    {
        id: 99,
        label: 'Honduran',
        value: 'Honduran',
    },
    {
        id: 100,
        label: 'Hong Kong, Hong Kongese',
        value: 'Hong Kong, Hong Kongese',
    },
    {
        id: 101,
        label: 'Hungarian, Magyar',
        value: 'Hungarian, Magyar',
    },
    {
        id: 102,
        label: 'Icelandic',
        value: 'Icelandic',
    },
    {
        id: 103,
        label: 'Indian',
        value: 'Indian',
    },
    {
        id: 104,
        label: 'Indonesian',
        value: 'Indonesian',
    },
    {
        id: 105,
        label: 'Iranian, Persian',
        value: 'Iranian, Persian',
    },
    {
        id: 106,
        label: 'Iraqi',
        value: 'Iraqi',
    },
    {
        id: 107,
        label: 'Irish',
        value: 'Irish',
    },
    {
        id: 108,
        label: 'Manx',
        value: 'Manx',
    },
    {
        id: 109,
        label: 'Israeli',
        value: 'Israeli',
    },
    {
        id: 110,
        label: 'Italian',
        value: 'Italian',
    },
    {
        id: 111,
        label: 'Jamaican',
        value: 'Jamaican',
    },
    {
        id: 112,
        label: 'Japanese',
        value: 'Japanese',
    },
    {
        id: 113,
        label: 'Channel Island',
        value: 'Channel Island',
    },
    {
        id: 114,
        label: 'Jordanian',
        value: 'Jordanian',
    },
    {
        id: 115,
        label: 'Kazakhstani, Kazakh',
        value: 'Kazakhstani, Kazakh',
    },
    {
        id: 116,
        label: 'Kenyan',
        value: 'Kenyan',
    },
    {
        id: 117,
        label: 'I-Kiribati',
        value: 'I-Kiribati',
    },
    {
        id: 118,
        label: 'North Korean',
        value: 'North Korean',
    },
    {
        id: 119,
        label: 'South Korean',
        value: 'South Korean',
    },
    {
        id: 120,
        label: 'Kuwaiti',
        value: 'Kuwaiti',
    },
    {
        id: 121,
        label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
        value: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
    },
    {
        id: 122,
        label: 'Lao, Laotian',
        value: 'Lao, Laotian',
    },
    {
        id: 123,
        label: 'Latvian',
        value: 'Latvian',
    },
    {
        id: 124,
        label: 'Lebanese',
        value: 'Lebanese',
    },
    {
        id: 125,
        label: 'Basotho',
        value: 'Basotho',
    },
    {
        id: 126,
        label: 'Liberian',
        value: 'Liberian',
    },
    {
        id: 127,
        label: 'Libyan',
        value: 'Libyan',
    },
    {
        id: 128,
        label: 'Liechtenstein',
        value: 'Liechtenstein',
    },
    {
        id: 129,
        label: 'Lithuanian',
        value: 'Lithuanian',
    },
    {
        id: 130,
        label: 'Luxembourg, Luxembourgish',
        value: 'Luxembourg, Luxembourgish',
    },
    {
        id: 131,
        label: 'Macanese, Chinese',
        value: 'Macanese, Chinese',
    },
    {
        id: 132,
        label: 'Macedonian',
        value: 'Macedonian',
    },
    {
        id: 133,
        label: 'Malagasy',
        value: 'Malagasy',
    },
    {
        id: 134,
        label: 'Malawian',
        value: 'Malawian',
    },
    {
        id: 135,
        label: 'Malaysian',
        value: 'Malaysian',
    },
    {
        id: 136,
        label: 'Maldivian',
        value: 'Maldivian',
    },
    {
        id: 137,
        label: 'Malian, Malinese',
        value: 'Malian, Malinese',
    },
    {
        id: 138,
        label: 'Maltese',
        value: 'Maltese',
    },
    {
        id: 139,
        label: 'Marshallese',
        value: 'Marshallese',
    },
    {
        id: 140,
        label: 'Martiniquais, Martinican',
        value: 'Martiniquais, Martinican',
    },
    {
        id: 141,
        label: 'Mauritanian',
        value: 'Mauritanian',
    },
    {
        id: 142,
        label: 'Mauritian',
        value: 'Mauritian',
    },
    {
        id: 143,
        label: 'Mahoran',
        value: 'Mahoran',
    },
    {
        id: 144,
        label: 'Mexican',
        value: 'Mexican',
    },
    {
        id: 145,
        label: 'Micronesian',
        value: 'Micronesian',
    },
    {
        id: 146,
        label: 'Moldovan',
        value: 'Moldovan',
    },
    {
        id: 147,
        label: 'Monégasque, Monacan',
        value: 'Monégasque, Monacan',
    },
    {
        id: 148,
        label: 'Mongolian',
        value: 'Mongolian',
    },
    {
        id: 149,
        label: 'Montenegrin',
        value: 'Montenegrin',
    },
    {
        id: 150,
        label: 'Montserratian',
        value: 'Montserratian',
    },
    {
        id: 151,
        label: 'Moroccan',
        value: 'Moroccan',
    },
    {
        id: 152,
        label: 'Mozambican',
        value: 'Mozambican',
    },
    {
        id: 153,
        label: 'Burmese',
        value: 'Burmese',
    },
    {
        id: 154,
        label: 'Namibian',
        value: 'Namibian',
    },
    {
        id: 155,
        label: 'Nauruan',
        value: 'Nauruan',
    },
    {
        id: 156,
        label: 'Nepali, Nepalese',
        value: 'Nepali, Nepalese',
    },
    {
        id: 157,
        label: 'Dutch, Netherlandic',
        value: 'Dutch, Netherlandic',
    },
    {
        id: 158,
        label: 'New Caledonian',
        value: 'New Caledonian',
    },
    {
        id: 159,
        label: 'New Zealand, NZ',
        value: 'New Zealand, NZ',
    },
    {
        id: 160,
        label: 'Nicaraguan',
        value: 'Nicaraguan',
    },
    {
        id: 161,
        label: 'Nigerien',
        value: 'Nigerien',
    },
    {
        id: 162,
        label: 'Nigerian',
        value: 'Nigerian',
    },
    {
        id: 163,
        label: 'Niuean',
        value: 'Niuean',
    },
    {
        id: 164,
        label: 'Norfolk Island',
        value: 'Norfolk Island',
    },
    {
        id: 165,
        label: 'Northern Marianan',
        value: 'Northern Marianan',
    },
    {
        id: 166,
        label: 'Norwegian',
        value: 'Norwegian',
    },
    {
        id: 167,
        label: 'Omani',
        value: 'Omani',
    },
    {
        id: 168,
        label: 'Pakistani',
        value: 'Pakistani',
    },
    {
        id: 169,
        label: 'Palauan',
        value: 'Palauan',
    },
    {
        id: 170,
        label: 'Palestinian',
        value: 'Palestinian',
    },
    {
        id: 171,
        label: 'Panamanian',
        value: 'Panamanian',
    },
    {
        id: 172,
        label: 'Papua New Guinean, Papuan',
        value: 'Papua New Guinean, Papuan',
    },
    {
        id: 173,
        label: 'Paraguayan',
        value: 'Paraguayan',
    },
    {
        id: 174,
        label: 'Peruvian',
        value: 'Peruvian',
    },
    {
        id: 175,
        label: 'Philippine, Filipino',
        value: 'Philippine, Filipino',
    },
    {
        id: 176,
        label: 'Pitcairn Island',
        value: 'Pitcairn Island',
    },
    {
        id: 177,
        label: 'Polish',
        value: 'Polish',
    },
    {
        id: 178,
        label: 'Portuguese',
        value: 'Portuguese',
    },
    {
        id: 179,
        label: 'Puerto Rican',
        value: 'Puerto Rican',
    },
    {
        id: 180,
        label: 'Qatari',
        value: 'Qatari',
    },
    {
        id: 181,
        label: 'Réunionese, Réunionnais',
        value: 'Réunionese, Réunionnais',
    },
    {
        id: 182,
        label: 'Romanian',
        value: 'Romanian',
    },
    {
        id: 183,
        label: 'Russian',
        value: 'Russian',
    },
    {
        id: 184,
        label: 'Rwandan',
        value: 'Rwandan',
    },
    {
        id: 185,
        label: 'Barthélemois',
        value: 'Barthélemois',
    },
    {
        id: 186,
        label: 'Saint Helenian',
        value: 'Saint Helenian',
    },
    {
        id: 187,
        label: 'Kittitian or Nevisian',
        value: 'Kittitian or Nevisian',
    },
    {
        id: 188,
        label: 'Saint Lucian',
        value: 'Saint Lucian',
    },
    {
        id: 189,
        label: 'Saint-Martinoise',
        value: 'Saint-Martinoise',
    },
    {
        id: 190,
        label: 'Saint-Pierrais or Miquelonnais',
        value: 'Saint-Pierrais or Miquelonnais',
    },
    {
        id: 191,
        label: 'Saint Vincentian, Vincentian',
        value: 'Saint Vincentian, Vincentian',
    },
    {
        id: 192,
        label: 'Samoan',
        value: 'Samoan',
    },
    {
        id: 193,
        label: 'Sammarinese',
        value: 'Sammarinese',
    },
    {
        id: 194,
        label: 'São Toméan',
        value: 'São Toméan',
    },
    {
        id: 195,
        label: 'Saudi, Saudi Arabian',
        value: 'Saudi, Saudi Arabian',
    },
    {
        id: 196,
        label: 'Senegalese',
        value: 'Senegalese',
    },
    {
        id: 197,
        label: 'Serbian',
        value: 'Serbian',
    },
    {
        id: 198,
        label: 'Seychellois',
        value: 'Seychellois',
    },
    {
        id: 199,
        label: 'Sierra Leonean',
        value: 'Sierra Leonean',
    },
    {
        id: 200,
        label: 'Singaporean',
        value: 'Singaporean',
    },
    {
        id: 201,
        label: 'Sint Maarten',
        value: 'Sint Maarten',
    },
    {
        id: 202,
        label: 'Slovak',
        value: 'Slovak',
    },
    {
        id: 203,
        label: 'Slovenian, Slovene',
        value: 'Slovenian, Slovene',
    },
    {
        id: 204,
        label: 'Solomon Island',
        value: 'Solomon Island',
    },
    {
        id: 205,
        label: 'Somali, Somalian',
        value: 'Somali, Somalian',
    },
    {
        id: 206,
        label: 'South African',
        value: 'South African',
    },
    {
        id: 207,
        label: 'South Georgia or South Sandwich Islands',
        value: 'South Georgia or South Sandwich Islands',
    },
    {
        id: 208,
        label: 'South Sudanese',
        value: 'South Sudanese',
    },
    {
        id: 209,
        label: 'Spanish',
        value: 'Spanish',
    },
    {
        id: 210,
        label: 'Sri Lankan',
        value: 'Sri Lankan',
    },
    {
        id: 211,
        label: 'Sudanese',
        value: 'Sudanese',
    },
    {
        id: 212,
        label: 'Surinamese',
        value: 'Surinamese',
    },
    {
        id: 213,
        label: 'Svalbard',
        value: 'Svalbard',
    },
    {
        id: 214,
        label: 'Swazi',
        value: 'Swazi',
    },
    {
        id: 215,
        label: 'Swedish',
        value: 'Swedish',
    },
    {
        id: 216,
        label: 'Swiss',
        value: 'Swiss',
    },
    {
        id: 217,
        label: 'Syrian',
        value: 'Syrian',
    },
    {
        id: 218,
        label: 'Chinese, Taiwanese',
        value: 'Chinese, Taiwanese',
    },
    {
        id: 219,
        label: 'Tajikistani',
        value: 'Tajikistani',
    },
    {
        id: 220,
        label: 'Tanzanian',
        value: 'Tanzanian',
    },
    {
        id: 221,
        label: 'Thai',
        value: 'Thai',
    },
    {
        id: 222,
        label: 'Timorese',
        value: 'Timorese',
    },
    {
        id: 223,
        label: 'Togolese',
        value: 'Togolese',
    },
    {
        id: 224,
        label: 'Tokelauan',
        value: 'Tokelauan',
    },
    {
        id: 225,
        label: 'Tongan',
        value: 'Tongan',
    },
    {
        id: 226,
        label: 'Trinidadian or Tobagonian',
        value: 'Trinidadian or Tobagonian',
    },
    {
        id: 227,
        label: 'Tunisian',
        value: 'Tunisian',
    },
    {
        id: 228,
        label: 'Turkish',
        value: 'Turkish',
    },
    {
        id: 229,
        label: 'Turkmen',
        value: 'Turkmen',
    },
    {
        id: 230,
        label: 'Turks and Caicos Island',
        value: 'Turks and Caicos Island',
    },
    {
        id: 231,
        label: 'Tuvaluan',
        value: 'Tuvaluan',
    },
    {
        id: 232,
        label: 'Ugandan',
        value: 'Ugandan',
    },
    {
        id: 233,
        label: 'Ukrainian',
        value: 'Ukrainian',
    },
    {
        id: 234,
        label: 'Emirati, Emirian, Emiri',
        value: 'Emirati, Emirian, Emiri',
    },
    {
        id: 235,
        label: 'British, UK',
        value: 'British, UK',
    },
    {
        id: 236,
        label: 'American',
        value: 'American',
    },
    {
        id: 238,
        label: 'Uruguayan',
        value: 'Uruguayan',
    },
    {
        id: 239,
        label: 'Uzbekistani, Uzbek',
        value: 'Uzbekistani, Uzbek',
    },
    {
        id: 240,
        label: 'Ni-Vanuatu, Vanuatuan',
        value: 'Ni-Vanuatu, Vanuatuan',
    },
    {
        id: 241,
        label: 'Venezuelan',
        value: 'Venezuelan',
    },
    {
        id: 242,
        label: 'Vietnamese',
        value: 'Vietnamese',
    },
    {
        id: 243,
        label: 'British Virgin Island',
        value: 'British Virgin Island',
    },
    {
        id: 244,
        label: 'U.S. Virgin Island',
        value: 'U.S. Virgin Island',
    },
    {
        id: 245,
        label: 'Wallis and Futuna, Wallisian or Futunan',
        value: 'Wallis and Futuna, Wallisian or Futunan',
    },
    {
        id: 246,
        label: 'Sahrawi, Sahrawian, Sahraouian',
        value: 'Sahrawi, Sahrawian, Sahraouian',
    },
    {
        id: 247,
        label: 'Yemeni',
        value: 'Yemeni',
    },
    {
        id: 248,
        label: 'Zambian',
        value: 'Zambian',
    },
    {
        id: 249,
        label: 'Zimbabwean',
        value: 'Zimbabwean',
    },
];
const textKeys = [
    'confirm_your_delivery_location',
    'yourOrders',
    'refund_Policy',
    'terms_Conditions',
    'privacy_Policy',
    'continue',
    'change',
    'veg_Only',
    'view_Cart',
    'menu',
    'review_order',
    'delivery_address',
    'instructions',
    'allergies_title',
    'allergies_msg',
    'item_Total',
    'total_Taxes',
    'grand_Total',
    'place_Order',
    'add_new_address',
    'building_No',
    'street_No',
    'zone_No',
    'area_Name',
    'contact_Number',
    'save_Address',
    'address_name',
    'cancel',
    'done',
    'no_timeslots',
    'delivering_to',
    'delivery_time',
    'confirm',
    'how_to_pay',
    'cash_on_delivery',
    'pay_by_card',
    'thank_You_title',
    'track_Order',
    'back_to_home',
    'my_orders',
    'item',
    'login',
    'logout',
    'register',
    'your_location',
    'change_language',
    'enter_your_delivery_location',
    'we_deliver_pure_hygenic_food',
    'explore_our_menu',
    'meal_crafted_menu_items',
    'order_menu',
    'delivery_fee',
    'delivered_by',
    'add',
    'customizable',
    'checkout',
    'items',
    'delivery_address',
    'you_have_logged_out',
    'forget_password',
    'change_password',
    'or',
    'login_with_us',
    'sent_a_temporary_password',
    'reset',
    'back_to_login',
    'registration_success',
    'cart_items',
    'add_order_instructions',
    'enter_cooking_instructions',
    'delivery_charges',
    'pay_now',
    'your_addresses',
    'address_not_added',
    'add_new_address',
    'close',
    'home',
    'office',
    'other',
    'confirm_location',
    'address',
    'enter_full_address',
    'address_added_successfully',
    'select',
    'minimum_item_total',
    'ok',
    'by_cash',
    'by_edc_machine',
    'thank_you_for_your_order',
    'order_number',
    'go_to_home',
    'old_password_required',
    'new_password_required',
    'confirm_new_password_required',
    'confirm_password_required',
    'select_atleast_one_option',
    'incorrect_password',
    'order_history',
    'order_date',
    'delivery_to',
    'track_order',
    'order_id',
    'delivery_notification',
    'order_placed',
    'order_placed_msg',
    'order_accepted',
    'order_accepted_msg',
    'on_the_way',
    'on_the_way_takeaway',
    'on_the_way_msg',
    'order_delivered',
    'order_delivered_takeaway',
    'order_delivered_msg',
    'order_coming_soon',
    'contact_support',
    'ordered_items',
    'items_total',
    'delivery_charges',
    'order_total',
    'cooking_instructions',
    'amount',
    'menu_not_found_msg',
    'disclaimer_msg',
    'no_items_found',
    'back_to_menu',
    'marriott_bonvoy_member',
    'enter_membership_no',
    'add_new_customization',
    'repeat_customization',
    'choose_customization',
    'add_customisation',
    'order_accepted_msg_takeaway',
    'on_the_way_msg_takeaway',
    'takeaway',
    'delivery',
    'deliveryType',
    'pickup_address',
    'if_not_membership',
    'click_Here',
    'address_title',
    'enter_first_name',
    'enter_last_name',
    'enter_email_address',
    'enter_password',
    'enter_confirm_password',
    'nationality',
    'registered_successfully',
    'you_have_logged_in',
    'please_select_an_address',
    'incorrect_password_try_again',
    'new_password_sent_to_mail',
    'no_user_found',
    'title_required',
    'confirm_location_required_for_delivery',
    'address_required',
    'building_No_required',
    'street_No_required',
    'zone_No_required',
    'enter_your_location_to_proceed',
    'location_not_serviceable',
    'mobile_no_required',
    'first_name_required',
    'last_name_required',
    'password_required',
    'enter_valid_email',
    'password_required',
    'mismatch_password',
    'no_orders_available',
    'no_orders_placed_yet',
    'something_went_wrong',
    'no_menu_available',
    'stay_up_to_date',
    'get_offers',
    'follow_us',
    'see_you_soon',
    'hungry_got_you_covered',
    'link_for_hotel_activation',
    'order_summary',
    'subtotal',
    'payment_method',
    'order_again',
    'note',
    'repeat_order_subtitle_pre',
    'repeat_order_subtitle_post',
    'repeat_order_subtitle_last',
    'not_available',
    'delivery_repeatLastOrder',
    'takeaway_repeatLastOrder',
    'do_not_press_back_msg',
    'repeat_order_subtitle'
];

export const directory = {
    en: {
        confirm_your_delivery_location: 'Confirm your delivery location',
        yourOrders: 'My Orders',
        refund_Policy: 'Refund Policy',
        terms_Conditions: 'Terms & Conditions',
        privacy_Policy: 'Privacy Policy',
        continue: 'Continue',
        change: 'Change',
        veg_Only: 'Veg Only',
        view_Cart: 'View Cart',
        menu: 'Outlet',
        review_order: 'Review order',
        delivery_address: 'Delivery Address',
        instructions: 'Instructions',
        allergies_title: 'Please let us know if you have any food allergies.',
        allergies_msg: 'Less spicy, extra hot etc.',
        item_Total: 'Items Total',
        total_Taxes: 'Total Taxes',
        grand_Total: 'Grand Total',
        place_Order: 'Place Order',
        add_new_address: 'Add New',
        building_No: 'Building No',
        street_No: 'Street No',
        zone_No: 'Zone No',
        area_Name: 'Area Name',
        contact_Number: 'Contact Number',
        save_Address: 'Save Address',
        address_name: 'Address name eg. Home, Office',
        cancel: 'Cancel',
        done: 'Done',
        no_timeslots: 'No time Slots available',
        delivering_to: 'Delivering to:',
        delivery_time: 'Delivery time',
        confirm: 'Confirm',
        how_to_pay: 'How would you like to pay?',
        cash_on_delivery: 'Pay on Delivery',
        pay_by_card: 'Pay By Card',
        thank_You_title: 'Thank You! Your order has been placed.',
        track_Order: 'Track Order',
        back_to_home: 'Back to home',
        my_orders: 'My Orders',
        item: 'Item',
        login: 'Subscribe',
        logout: 'Logout',
        register: 'Register',
        your_location: 'Your location',
        change_language: 'Change Language',
        enter_your_delivery_location: 'Enter your delivery location',
        we_deliver_pure_hygenic_food:
            'We deliver pure hygienic food at your doorstep!',
        explore_our_menu: 'Explore our Menu',
        meal_crafted_menu_items: 'Meal crafted menu items',
        order_menu: 'Order Menu',
        delivery_fee: 'Delivery fee',
        delivered_by: 'Delivered by',
        add: 'Add',
        customizable: 'Customizable',
        checkout: 'Checkout',
        items: 'items',
        you_have_logged_out: 'You have logged out!',
        forget_password: 'Forgot Password',
        change_password: 'Change Password',
        or: 'OR',
        login_with_us: 'Login with Us',
        sent_a_temporary_password: "We'll sent a new password on your E-mail",
        reset: 'Reset',
        back_to_login: 'Back to Login',
        registered_successfully: 'Registered Successfully!',
        cart_items: 'Cart Items',
        add_order_instructions: 'Add Order instructions',
        enter_cooking_instructions: 'Enter cooking instructions',
        delivery_charges: 'Delivery Fee',
        pay_now: 'Pay Now',
        your_addresses: 'Your Addresses',
        address_not_added: "You haven't added any addresses yet!",
        close: 'Close',
        home: 'Home',
        office: 'Office',
        other: 'Other',
        confirm_location: 'Confirm Location',
        address: 'Address',
        enter_full_address: 'Enter full Address',
        address_added_successfully: 'Address has been added successfully!',
        select: 'Select',
        minimum_item_total:
            'QAR 350 minimum item total required to place an order.',
        ok: 'OK',
        thank_you_for_your_order:
            'Thank you for ordering with us! We hope you enjoy your meal.',
        order_number: 'Order Number:',
        go_to_home: 'Back to home',
        password_required: 'Password is required',
        old_password_required: 'Old Password is required!',
        new_password_required: 'New Password is required!',
        confirm_new_password_required: 'Confirm New Password is required!',
        confirm_password_required: 'Confirm Password is required!',
        select_atleast_one_option:
            'Kindly select at least one option to proceed further.',
        incorrect_password: 'Incorrect password. Please try again.',
        order_history: 'Order History',
        order_date: 'Order Date',
        delivery_to: 'Delivery To',
        track_order: 'Track Order',
        order_id: 'Order ID:',
        order_placed: 'Order Placed!',
        order_accepted: 'Order Accepted!',
        delivery_notification:
            "You'll be notified once your order is out for delivery.",
        on_the_way: 'On The Way!',
        on_the_way_takeaway: 'Order Awaits!',
        order_coming_soon: 'Coming soon! Your order is on its way.',
        order_delivered: 'Order Delivered!',
        order_delivered_takeaway: 'Order Picked!',
        contact_support: "Enjoy your food! We're just a call or chat away.",
        ordered_items: 'Ordered Items:',
        items_total: 'Items Total',
        order_total: 'Order Total',
        cooking_instructions: 'Cooking Instructions',
        amount: 'Amount',
        choose_payment: 'Choose Payment',
        order_info: 'Order Information',
        logged_in_message: 'You have logged in',
        password_updated: 'Your password has been updated',
        change_your_password: 'Enter below details to change your password',
        enter_mobile_number: 'Enter Mobile Number',
        enter_old_password: 'Enter Old Password',
        enter_new_password: 'Enter New Password',
        confirm_new_password: 'Enter Confirm New Password',
        menu_not_found_msg:
            "OOPS! you've made a fab choice with W2GO! Our delivery magic happens from noon till midnight. If you crave more info or just want a chit-chat 77943975, or don't hesitate to ring us on 44535135.",
        disclaimer_msg:
            "Please enjoy your meal within 2 hours of delivery. For hot dishes, ensure they are maintained at a temperature of 65°C, while cold dishes should be below 4°C. If reheating is necessary, please heat the food to a minimum of 82°C. It's important to note that W Doha cannot assume responsibility if these instructions are not followed.",
        no_items_found: 'No items in the cart',
        back_to_menu: 'Back to menu',
        marriott_bonvoy_member: 'Marriott Bonvoy member ?',
        enter_membership_no:
            'Enter your membership number to earn exclusive points',
        add_new_customization: 'Add new customization',
        repeat_customization: 'Repeat customization',
        choose_customization: 'Choose customization',
        add_customisation: 'Add Customisation',
        order_placed_msg: 'Confirmation of your order is in progress.',
        order_accepted_msg:
            " You'll be notified once your order is out for delivery.",
        on_the_way_msg: 'Coming soon! Your order is on its way.',
        order_accepted_msg_takeaway:
            "You'll be notified once your order is ready for pick-up.",
        on_the_way_msg_takeaway: 'Your order is ready for pick-up!',
        order_delivered_msg:
            'Enjoy your food! We are just a call or chat away.',
        takeaway: 'Pick-up from hotel',
        delivery: 'Delivery at your place',
        deliveryType: 'Please select the delivery type',
        select_payment_method: 'Select Payment Method',
        pickup_address: 'Pickup Address',
        if_not_membership: 'If Not, get your membership',
        click_Here: 'Click here',
        address_title: 'Address Title',
        enter_first_name: 'Enter first name *',
        enter_last_name: 'Enter last name *',
        enter_email_address: 'Enter E-mail Address *',
        enter_password: 'Enter Password *',
        enter_confirm_password: 'Enter Confirm Password *',
        nationality: 'Nationality',
        you_have_logged_in: 'You have logged in!',
        please_select_an_address: 'Please select an address!',
        incorrect_password_try_again:
            'Incorrect old password. Please try again.',
        new_password_sent_to_mail: 'A new password has been sent to your mail',
        no_user_found: 'No user found. Please register with us.',
        password_updated_successfully:
            'Your password has been updated successfully',
        title_required: 'Title is required',
        confirm_location_required_for_delivery:
            'Confirm location is required for smooth delivery at your doostep.',
        address_required: 'Address is required',
        building_No_required: 'Building No is required.',
        street_No_required: 'Street No is required.',
        zone_No_required: 'Zone No is required.',
        enter_your_location_to_proceed:
            'Please enter your location to proceed further',
        location_not_serviceable:
            'Looks like your location is not serviceable. Would you like to opt for takeaway instead?',
        first_name_required: 'First Name is required!',
        last_name_required: 'Last Name is required!',
        mobile_no_required: 'Mobile No is required',
        enter_valid_email: 'Enter a valid email',
        mismatch_password: 'Password and confirm password mismatch.',
        no_orders_available: 'No Orders Available! ',
        no_orders_placed_yet: 'No Orders placed yet.',
        something_went_wrong: 'Something went wrong! Please try again.',
        no_menu_available: 'No Menu Available',
        stay_up_to_date: 'Stay up to date with our latest offers: ',
        get_offers: 'Get Offers',
        follow_us: 'Follow us on: ',
        see_you_soon: 'See you soon',
        hungry_got_you_covered: "Hungry? We've got you covered",
        by_cash: 'Cash on Delivery',
        by_edc_machine: 'Card on Delivery',
        by_cash_pickup: 'Cash on Pickup',
        by_edc_machine_pickup: 'Card on Pickup',
        link_for_hotel_activation: 'Link for hotel activation',
        order_summary: 'Order Summary',
        subtotal: 'Subtotal',
        payment_method: 'Payment Method',
        order_again: 'Order Again',
        accept_and_continue: 'Accept and Continue',
        repeat_order_title: 'Do you want to repeat this order',
        note: 'Note',
        repeat_order_subtitle_pre: 'This is a',
        repeat_order_subtitle_post:
            'order and all the other items will get removed if already added from',
        repeat_order_subtitle_last: 'service type',
        not_available: 'Not Available',
        delivery_repeatLastOrder: 'Delivery',
        takeaway_repeatLastOrder: 'Takeaway',
        do_not_press_back_msg : 'Please do not press back or close the app. we\'re placing your order.',
        'repeat_order_subtitle': 'There are few items that exists in your cart. Those items will be removed if you perform this action. It could be possible that few of these items are not available currently.'

    },
    ar: {
        confirm_your_delivery_location: 'يرجى تأكيد موقع التوصيل',
        yourOrders: 'طلباتك',
        refund_Policy: 'سياسة الاسترداد',
        terms_Conditions: 'الشروط والأحكام',
        privacy_Policy: 'سياسة الخصوصية',
        continue: 'المتابعة',
        change: 'تغيير',
        veg_Only: 'نباتي فقط',
        view_Cart: 'عرض محتويات سلة المشتريات',
        menu: 'قائمة الطعام',
        review_order: 'اطلع على الطلب',
        delivery_address: 'عنوان التوصيل',
        instructions: 'التعليمات',
        allergies_title: 'يرجى إبلاغنا إذا كان لديك أي حساسية غذائية',
        allergies_msg: 'فلفل حار أقل ، إضافة الكثير من الفلفل الحار، إلخ',
        item_Total: 'مجموع الطلبات',
        total_Taxes: 'مجموع الضرائب',
        grand_Total: 'المجموع الكلي',
        place_Order: 'تأكيد الطلب',
        add_new_address: 'أضف عنوان جديد',
        building_No: 'رقم المبنى',
        street_No: 'رقم الشارع',
        zone_No: 'رقم المنطقة',
        area_Name: 'رقم البلدة',
        contact_Number: 'رقم جهة الاتصال',
        save_Address: 'حفظ العنوان',
        address_name: 'العنوان، على سبيل المثال، المنزل، المكتب',
        cancel: 'إلغاء',
        done: 'تم',
        no_timeslots: 'لا توجد فترات زمنية متاحة',
        delivering_to: 'التوصيل إلى:',
        delivery_time: 'وقت التوصيل',
        confirm: 'تأكيد',
        how_to_pay: 'كيف تفضّل الدفع؟',
        cash_on_delivery: 'الدفع نقداً عند الاستلام',
        pay_by_card: 'Pay By Card',
        thank_You_title: 'شكرا لك! تم تأكيد طلبك.',
        track_Order: 'تتبع طلبك',
        back_to_home: 'العودة إلى الصفحة الرئيسية',
        my_orders: 'طلباتي',
        item: 'الطلب',
        login: 'تسجيل دخول',
        logout: 'تسجيل خروج',
        register: 'يسجل',
        your_location: 'موقعك',
        change_language: 'تغير اللغة',
        enter_your_delivery_location: 'أدخل بييانات عنوان التوصيل',
        we_deliver_pure_hygenic_food:
            'نقوم بتوصيل طعاما نظيفا و صحيا إلى باب منزلك',
        explore_our_menu: 'استكشف المنيو',
        meal_crafted_menu_items: 'قائمة طعام مصممة',
        order_menu: 'قائمة الطلبات',
        delivery_fee: 'رسوم التوصيل ',
        delivered_by: 'تم التوصيل من طرف ',
        add: 'إضافة',
        customizable: 'قابلة للتخصيص',
        checkout: 'خروج',
        items: 'مواد',
        you_have_logged_out: 'لقد تم تسجيل الخروج',
        forget_password: 'نسيان كلمة السر',
        change_password: 'تغير كلمة السر',
        or: 'أو',
        login_with_us: 'سجل الدخول معنا',
        sent_a_temporary_password:
            'سيوف نقوم ببعث كلمة السر إلى بريدك الإلكتروني',
        reset: 'إعادة تعين',
        back_to_login: 'العودة إلى تسجيل الدخول',
        registration_success: 'تم التسجيل بنجاح',
        cart_items: 'عناصر البطاقة',
        add_order_instructions: 'أضف تعليمات متعلقة بالطلب',
        enter_cooking_instructions: 'أضف تعليمات متعلقة بالطبخ',
        delivery_charges: 'رسوم التوصيل',
        pay_now: 'ادفع الأن',
        your_addresses: 'عنوانك',
        address_not_added: 'لم تقم بإضافة عنوانك بعد',
        close: 'أغلق',
        home: 'البيت',
        office: 'المكتب',
        other: 'اخر',
        confirm_location: 'تأكيد العنوان',
        address: 'العنوان',
        enter_full_address: 'أدخل  العنوان كامل',
        address_added_successfully: 'تمت إضافة العنوان بنجاح',
        select: 'إختر',
        minimum_item_total: 'المبلغ الأدنى المطلوب للحجز هو 350 ريال',
        ok: 'اوكي',
        thank_you_for_your_order: 'شكراً لطلبك معنا ونأمل أن تستمتع بوجبتك.',
        go_to_home: 'إذهب إلى الصفحة الرئيسية',
        old_password_required: 'مطلوب كلمة السر القديمة',
        new_password_required: 'مطلوب كلمة السرالجديدة',
        confirm_new_password_required: 'مطلوب تأكيد كلمة السر الجديدة',
        confirm_password_required: 'مطلوب تأكيد كلمة السر',
        select_atleast_one_option:
            'الرجاء الضغط  إختيار واحد على الأقل للمضي قدما',
        incorrect_password: 'يوجد خطأ في كلمة السر الرجاء تكرار المحاولة',
        order_history: 'سجل الطلبات',
        order_date: 'تاريخ الطلبات',
        delivery_to: 'يسلم إلى',
        track_order: 'متابعة الطلب',
        order_id: 'هوية الطلب',
        order_placed: 'تم الطلب',
        order_accepted: 'تم قبول الطلب',
        delivery_notification: 'سيتم إعلامكم عند خروج الطلب للتوصيل',
        on_the_way: 'في الطريق',
        on_the_way_takeaway: 'طلبك ينتظر',
        order_coming_soon: 'طلبكم سيصل قريبا',
        order_delivered: 'تم التوصيل',
        order_delivered_takeaway: 'لقد تم جمع طلبك من الفندق',
        contact_support: 'استمتع بوجبتك! في انتظار طلبكم التالي',
        ordered_items: 'المواد المطلوبة',
        items_total: 'المجموع',
        order_total: 'المجموع',
        cooking_instructions: 'تعليمات الاكل',
        amount: 'كمية',
        choose_payment: 'اختر الدفع',
        order_info: 'معلومات الطلب',
        logged_in_message: 'لقد قمت بتسجيل الدخول',
        password_updated: 'بك تم تحديث كلمة المرور الخاصة',
        change_your_password:
            'أدخل التفاصيل أدناه لتغيير كلمة المرور الخاصة بك.',
        enter_mobile_number: 'أدخل رقم الجوال',
        enter_old_password: 'أدخل كلمة المرور القديمة',
        enter_new_password: 'أدخل كلمة المرور الجديدة',
        confirm_new_password: 'أدخل تأكيد كلمة المرور الجديدة',
        menu_not_found_msg:
            ' لقد قمت باختيار رائع مع W2GO! سحر التوصيل لدينا يحدث من الظهيرة حتى منتصف الليل. إذا كنت تشتهي المزيد من المعلومات أو ترغب فقط في الدردشة، يمكنك الاتصال بنا على 77943975، أو لا تتردد في الاتصال بنا على 44535135.',
        disclaimer_msg:
            'نرجو منكم التمتع بوجبتكم في غضون ساعتين من وقت التوصيل. بالنسبة للأطباق الساخنة، يجب الحفاظ على درجة حرارتها عند 65 درجة مئوية، في حين يجب أن تبقى الأطباق الباردة أقل من 4 درجات مئوية. إذا كان هناك حاجة لإعادة تسخين الطعام، يرجى تسخينه إلى درجة حرارة لا تقل عن 82 درجة مئوية. يجب التنويه بأهمية أن فندق W Doha لا يمكن أن يتحمل المسؤولية في حالة عدم اتباع هذه التعليمات.',
        no_items_found: 'لا توجد عناصر في السلة',
        back_to_menu: 'العودة إلى القائمة',
        marriott_bonvoy_member: 'عضو ماريوت بونفوي؟',
        enter_membership_no: 'أدخل رقم عضويتك للحصول على نقاط حصرية',
        add_new_customization: 'إضافة تخصيص جديد',
        repeat_customization: 'كرر التخصيص',
        choose_customization: 'اختر التخصيص',
        add_customisation: 'إضافة التخصيص',
        order_placed_msg: 'جاري التأكيد على قبول طلبكم',
        order_accepted_msg: 'سيتم إعلامكم عند خروج الطلب للتوصيل',
        on_the_way_msg: 'طلبكم سيصل قريبا',
        order_delivered_msg: 'استمتع بطعامك! نحن مجرد مكالمة أو الدردشة بعيدا',
        order_accepted_msg_takeaway:
            'سيتم إعلامك عندما يصبح طلبك جاهزًا للاستلام',
        on_the_way_msg_takeaway: 'طلبك جاهز للاستلام',
        takeaway: 'استلام من الفندق',
        delivery: 'خدمة التوصيل إلى المنزل',
        deliveryType: 'الرجاء تحديد نوع التسليم',
        select_payment_method: 'اختر طريقة الدفع',
        pickup_address: 'عنوان الاستلام',
        if_not_membership: 'إذا لم تكن عضواً، انقر هنا',
        click_Here: 'انقر هنا',
        address_title: 'عنوان الموقع',
        enter_first_name: 'أدخل الاسم الأول',
        enter_last_name: 'إدخال اسم آخر',
        enter_email_address: 'أدخل عنوان البريد الإلكتروني',
        enter_password: 'أدخل كلمة المرور',
        enter_confirm_password: 'أدخل تأكيد كلمة المرور',
        nationality: 'الجنسية',
        registered_successfully: 'مسجل بنجاح',
        you_have_logged_in: 'لقد قمت بتسجيل الدخول',
        please_select_an_address: 'الرجاء تحديد عنوان',
        incorrect_password_try_again: 'كلمة سر خاطئة. حاول مرة اخرى.',
        new_password_sent_to_mail: 'تم إرسال كلمة المرور الجديدة إلى بريدك',
        no_user_found: 'لم يتم العثور على المستخدم. يرجى التسجيل معنا.',
        password_updated_successfully: 'لقد تم تحديث كلمة السر الخاصة بك بنجاح',
        title_required: 'العنوان مطلوب',
        confirm_location_required_for_delivery:
            'تأكيد الموقع مطلوب للتسليم السلس على عتبة داركم.',
        address_required: 'العنوان مطلوب',
        building_No_required: 'مطلوب رقم المبنى',
        enter_your_location_to_proceed: 'الرجاء إدخال موقعك للمضي قدما',
        location_not_serviceable:
            'يبدو أن موقعك غير صالح للخدمة. هل ترغب في اختيار الوجبات الجاهزة بدلا من ذلك؟',
        first_name_required: 'الإسم الأول مطلوب!',
        last_name_required: 'إسم العائلة مطلوب!',
        mobile_no_required: 'رقم الجوال مطلوب',
        enter_valid_email: 'أدخل بريد إلكتروني متاح',
        password_required: 'كلمة المرور مطلوبة',
        mismatch_password: 'كلمة المرور وتأكيد عدم تطابق كلمة المرور.',
        no_orders_available: 'لا توجد طلبات متاحة',
        no_orders_placed_yet: 'لم يتم وضع أي أوامر حتى الآن',
        something_went_wrong: 'حدث خطأ ما! يرجى المحاولة مرة أخرى.',
        no_menu_available: 'لا توجد قائمة متاحة',
        stay_up_to_date: 'ابق على اطلاع على أحدث عروضنا.',
        order_number: 'رقم الطلب',
        follow_us: 'تابعنا على',
        see_you_soon: 'نراكم قريبا',
        get_offers: 'العروضات',
        hungry_got_you_covered: 'جوعان؟ لدينا ما تحتاجه',
        order_summary: 'ملخص الطلب',
        subtotal: 'الإجمالي الفرعي',
        by_cash: 'الدفع نقدًا عند التوصيل',
        by_edc_machine: 'الدفع بالبطاقة عند التوصيل',
        by_cash_pickup: 'الدفع نقدًا عند الاستلام',
        by_edc_machine_pickup: 'الدفع بالبطاقة عند الاستلام',
        link_for_hotel_activation: 'رابط فعاليات الفندق',
        payment_method: 'طريقة الدفع',
        order_again: 'أطلب مرة أخرى',
        accept_and_continue: 'قبول ومتابعة',
        note: 'ملحوظة:',
        repeat_order_subtitle_pre: 'هذا هو',
        repeat_order_subtitle_post:
            'ستتم إزالة الطلب وجميع العناصر الأخرى إذا تمت إضافتها بالفعل من',
        repeat_order_subtitle_last: 'نوع الخدمة',
        not_available:'غير متاح',
        delivery_repeatLastOrder: 'توصيل',
        takeaway_repeatLastOrder: 'تيك أواي',
        do_not_press_back_msg:'من فضلك لا تضغط على الخلف أو تغلق التطبيق. نحن نضع طلبك.',
        'repeat_order_subtitle':'هناك عدد قليل من العناصر في سلة التسوق الخاصة بك. ستتم إزالة هذه العناصر إذا قمت بتنفيذ هذا الإجراء. قد يكون من الممكن أن يكون عدد قليل من هذه العناصر غير متوفر حاليًا'
    },
};
// generator function

// const generatedKeysData = (keysList,directory)=>{
//     const lang = ['US',"AR"];
//     const datares = lang.reduce((acc,item)=>{
//             keysList.forEach(keyName=>{
//                 if(!acc[item][keyName]){
//                     acc[item][keyName] = ''
//                 }

//             })
//         return acc
//     },directory)
// return datares

// }
