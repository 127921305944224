import React, { useEffect, useState } from 'react';
import { removeLocalCartItem, updateCartItem } from '../services/cartHelper';
import { themeColors } from '../theme';
import Counter from './Counter';
import { renderCurrency } from '../services/commonHelper';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useLanguage } from '../context/LanguageContext';

let menuItem = {};
function AddonRepeatConfirm(props) {
    const {
        originalItem,
        selectedItemList,
        close,
        updateOperationType,
        updateLocalCartItems,
        dismissRepeatAddon,
        dismissAddon,
    } = props;
    const [selectedListItem, setSelectedListItem] = useState([]);
    const { translation } = useLanguage();
    useEffect(() => {
        menuItem = JSON.parse(JSON.stringify(originalItem));
        // Filtering sub-addons & without-sub-addons to extract selected item
    }, [originalItem]);

    useEffect(() => {
        setSelectedItemList();
    }, [menuItem, menuItem.cartItems]);

    function setSelectedItemList() {
        let myselectedItemList = menuItem?.cartItems?.map((item) => {
            let itemPrice = 0;
            const tempSubItem = {
                itemName: item.name,
                type: item.type,
                addons: [], // contains addon-list and sub addons list,
                itemPrice,
                itemCount: item.cart_item_count,
                cartItemId: item.cartItemId,
            };
            // adding selected addons list
            const updateTempSubItem = (addOns, title = null) => {
                addOns.forEach((addOn) => {
                    if (addOn.quantity === 1) {
                        let addonEle = {
                            title: title || '',
                            addOnName: addOn.name,
                            addOnCount: addOn.quantity,
                            addOnPrice: addOn.price,
                        };
                        tempSubItem.addons.push(addonEle);
                    } else if (addOn.quantity > 0) {
                        const addonEle = {
                            title: title || '',
                            addOnName: addOn.name,
                            addOnCount: addOn.quantity,
                            addOnPrice: addOn.price,
                        };
                        tempSubItem.addons.push(addonEle);
                    }
                    if (addOn.price > 0) {
                        itemPrice += addOn.price * addOn.quantity;
                    }
                });
            };

            // adding sub-addon category and without sub-addon items in a single place
            item.sub_addons?.forEach((subAdd) => {
                updateTempSubItem(subAdd.addons, subAdd.name);
            });
            updateTempSubItem(item.without_subaddon_addons);
            tempSubItem.itemPrice = itemPrice;
            return tempSubItem;
        });
        setSelectedListItem(myselectedItemList);
    }

    function updateMenuItemCount(itemId, count) {
        let menuCartItem = '';
        menuItem.cartItems = menuItem?.cartItems?.map((cartItem) => {
            if (itemId === cartItem.cartItemId) {
                cartItem.cart_item_count += count;
                menuCartItem = cartItem;
            }
            return cartItem;
        });
        // setMenuItem(menuItem)
        updateCartItem(menuCartItem, () => updateLocalCartItems());
    }

    function removeItem(item) {
        const itemId = item.cartItemId;

        if (item.itemCount === 1) {
            // Removing cart item if count is 1  //
            menuItem.cartItems = menuItem?.cartItems?.filter(
                (cartItem) => itemId !== cartItem.cartItemId
            );
            // setMenuItem(menuItem)
            removeLocalCartItem(item.cartItemId, () => updateLocalCartItems());
        } else {
            // Decrementing item count is count > 1 //
            updateMenuItemCount(itemId, -1);
        }
        setSelectedItemList();
    }

    function duplicateItem(item) {
        const itemId = item.cartItemId;
        updateMenuItemCount(itemId, 1);
        setSelectedItemList();
    }
    async function addNewAddon() {
        dismissRepeatAddon();
        updateOperationType({ operation: 'add', menuItem: menuItem });
    }
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col bg-white rounded-2xl overflow-hidden mb-4'>
                {originalItem.thumbnail && (
                    <img
                        className='w-full h-40'
                        src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menu-items/${originalItem.thumbnail}`}
                        alt='menu name'
                    />
                )}
                <div
                    className='flex flex-col px-3 py-4'
                    style={{ backgroundColor: '#f2f6fc' }}
                >
                    <div
                        className='text-md font-medium'
                        style={{ fontSize: 16 }}
                    >
                        {originalItem?.name}
                    </div>
                </div>
            </div>

            <div className='addon-repeat-confirm addonModal-wrapper'>
                {selectedListItem?.map((item) => {
                    return (
                        <div
                            className={
                                'flex bg-white rounded-3xl mb-4 flex-1 shadow border border-gray-200 flex-row p-3 py-4'
                            }
                            key={item.cartItemId}
                        >
                            <div
                                className={
                                    'w-full flex-col justify-between flex-1 pt-0'
                                }
                            >
                                <div className='text-sm font-normal'>
                                    {item.itemName}
                                </div>

                                {item.addons.length > 0 &&
                                    item?.addons?.map((add, index) => (
                                        <div
                                            className='pl-3'
                                            key={`${add.title}-${index}`}
                                        >
                                            <div className='text-xs text-gray-700'>
                                                - {add.title}: {add.addOnName} -{' '}
                                                {add.addOnPrice > 0 &&
                                                    renderCurrency(
                                                        add.addOnPrice
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                {item.itemPrice > 0 && (
                                    <div className='text-xs font-bold mt-2'>
                                        {renderCurrency(
                                            item.itemPrice * item.itemCount
                                        )}
                                    </div>
                                )}
                                {/* <div className='flex-row w-full justify-between items-center mt-auto'>
                                    <div  className='text-xs font-bold'>
                                        {item.itemPrice > 0 &&
                                            renderCurrency(
                                                
                                            )}
                                    </div>
                                </div> */}
                            </div>
                            <div className='relative flex flex-col justify-start items-center'>
                                <Counter
                                    decrease={() => removeItem(item)}
                                    increase={() => duplicateItem(item)}
                                    count={item.itemCount}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='w-full flex justify-center items-center mb-6'>
                <Button
                    className='text-sm font-medium flex-row p-3 rounded-lg flex justify-center items-center gap-1'
                    onClick={() => addNewAddon()}
                    style={{
                        color: themeColors.primaryColor,
                    }}
                >
                    <PlusOutlined
                        style={{
                            color: themeColors.primaryColor,
                        }}
                    />
                    {translation['add_new_customization']}
                </Button>
            </div>
        </div>
    );
}

export default AddonRepeatConfirm;
