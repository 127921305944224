import React from 'react';
import { themeColors } from '../../theme';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

const ForgetPassword = (props) => {
    const {
        userData,
        inputChangeHandler,
        loading,
        forgetPasswordBtn,
        setCurrentView,
        translation,
    } = props;
    return (
        <div className='flex flex-col w-full'>
            <div className='mb-2'>
                <div className='text-sm'>
                    {translation['enter_mobile_number']}
                </div>
                <div className='text-xs text-gray-500'>
                    {translation['sent_a_temporary_password']}
                </div>
            </div>
            <div className='mb-2 flex flex-row gap-2'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3 w-10 bg-gray-100 text-center'
                    placeholder='Country code *'
                    onChange={(e) =>
                        inputChangeHandler(
                            'mobile_country_code',
                            e.target.value
                        )
                    }
                    
                    value={`+${userData.mobile_country_code}`}
                    maxLength={4}
                    autoFocus
                    style={{ width: 70 }}
                    disabled={true}
                />
                <Input
                type='number'
                pattern='[0-9]*'
                inputMode='numeric'
                    className='h-12 border rounded-xl border-gray-300 p-3 flex-1'
                    placeholder={translation['enter_mobile_number']}
                    onChange={(e) =>
                        inputChangeHandler('mobile', e.target.value)
                    }
                    
                    value={userData.mobile}
                    maxLength={12}
                    autoFocus
                />
            </div>

            <div className='flex flex-col mt-2 items-center justify-center w-full'>
                <Button
                    className='flex-row bg-gray-50 p-3 px-10 rounded-full shadow w-36 h-11 justify-center items-center'
                    style={{
                        backgroundColor: themeColors.primaryColor,
                    }}
                    onClick={forgetPasswordBtn}
                >
                    {loading ? (
                        <LoadingOutlined />
                    ) : (
                        <div className='text-sm font-medium text-white'>
                            {translation['reset']}
                        </div>
                    )}
                </Button>
                <div
                    className='flex-row justify-center items-center mt-2 ml-auto'
                    onClick={() => setCurrentView('mobile')}
                    disabled={loading}
                >
                    <div
                        className='text-sm font-medium  '
                        style={{ color: themeColors.primaryColor }}
                    >
                        {translation['back_to_login']}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
