import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import TextArea from 'antd/es/input/TextArea';

const CookingIntructions = (props) => {
    const { setCookingInstruction, cookingInstruction } = props;

    const [showInstructionInput, setShowInstructionInput] =
        React.useState(true);
    const { translation } = useLanguage();

    return (
        <div className='flex flex-col bg-white p-3 rounded-xl mb-4 flex-1 shadow'>
            <div
            // onClick={() => {
            //     setShowInstructionInput((prevState) => !prevState);
            // }}
            >
                <div className='flex flex-1 flex-row justify-between '>
                    <div className='text-sm text-black'>
                        {translation['add_order_instructions']}
                    </div>
                    {/* <DownOutlined /> */}
                </div>
            </div>

            {showInstructionInput && (
                <TextArea
                    className='h-16 border rounded-lg border-gray-300 p-3 mt-2'
                    style={{ textAlignVertical: 'top' }}
                    placeholder={translation['enter_cooking_instructions']}
                    onChange={(e) => setCookingInstruction(e.target.value)}
                    value={cookingInstruction}
                />
            )}
        </div>
    );
};

export default CookingIntructions;
