import { configureStore } from '@reduxjs/toolkit';
// import commonSlice from './slices/common/commonSlice';
// import cartSlice from './slices/cart/cartSlice';
import hotelSlice from './slices/hotel/hotelSlice';

// import thunk from 'redux-thunk';

const store = configureStore({
    reducer: {
        hotelSlice,
        devTools: process.env.NODE_ENV !== 'production',
        // middleware: [thunk],
    },
});

export default store;
