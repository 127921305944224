const themePalettes = [
    {
        primaryColor: '#8a5cc4',
        SecondarColor: '#000',
        bgColor: (opacity) => `rgba(138, 92, 196, ${opacity})`,
    },
    {
        primaryColor: '#0B2447',
        SecondarColor: '#000',
        bgColor: (opacity) => `rgba(11, 36, 71, ${opacity})`,
    },
    {
        primaryColor: '#2D033B',
        SecondarColor: '#000',
        bgColor: (opacity) => `rgba(45, 3, 59, ${opacity})`,
    },
    {
        primaryColor: '#2C3639',
        SecondarColor: '#000',
        bgColor: (opacity) => `rgba(44, 54, 57, ${opacity})`,
    },
    {
        primaryColor: '#001529',
        SecondarColor: '#000',
        bgColor: (opacity) => `rgba(0, 21, 41, ${opacity})`,
    },
];

export const themeColors = {
    ...themePalettes[0],
};

// primaryColor: '#8a5cc4',
