import { Route, Routes } from 'react-router-dom';
import HomeScreen from './pages/HomeScreen';
import MenuListScreen from './pages/MenuListScreen';
import OrderMenuScreen from './pages/OrderMenuScreen';
import CheckoutScreen from './pages/CheckoutScreen';
import ChangeAddress from './pages/ChangeAddress';
import AddNewAddressScreen from './pages/AddNewAddressScreen';
import OrderHistoryScreen from './pages/OrderHistoryScreen';
import TrackOrderScreen from './pages/TrackOrderScreen';
import LoginScreen from './pages/auth/LoginScreen';
import ThankYouScreen from './pages/ThankYouScreen';
import SplashScreen from './pages/SplashScreen';
import { USER_LOCATION } from './services/Typecode';
import React, { useEffect } from 'react';
import Onboarding from './pages/Onboarding';
import PageNotFound from './components/PageNotFound';
import ReactGA from 'react-ga4';

const App = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, [])

    React.useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let userData = {
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                };
                localStorage.setItem(USER_LOCATION, JSON.stringify(userData));
            });
        } else {
            console.log('Geolocation is not available in your browser.');
        }
    }, []);

    return (
        <Routes>
            <Route path='splashscreen' element={<SplashScreen />}></Route>
            <Route path='/' element={<Onboarding />}></Route>
            <Route path='home' element={<HomeScreen />}></Route>
            <Route path='menulist' element={<MenuListScreen />}></Route>
            <Route path='ordermenu' element={<OrderMenuScreen />}></Route>
            <Route path='checkout' element={<CheckoutScreen />}></Route>
            <Route path='changeaddress' element={<ChangeAddress />} />
            <Route path='addNewaddress' element={<AddNewAddressScreen />} />
            <Route path='orderhistory' element={<OrderHistoryScreen />}></Route>
            <Route path='trackorder' element={<TrackOrderScreen />} />
            <Route path='login' element={<LoginScreen />}></Route>
            <Route path='thankyou' element={<ThankYouScreen />}></Route>
            <Route path='page-not-found' element={<PageNotFound />}></Route>
            <Route path='*' element={<PageNotFound />}></Route>
        </Routes>
    );
};

export default App;
