import React from 'react';
import { themeColors } from '../theme';
import { LoadingOutlined } from '@ant-design/icons';
import Patment from './../assets/payment.png';
import { Button, Radio } from 'antd';
import { SERVICETYPE } from '../services/Typecode';

const PaymentComponent = (props) => {
    const {
        paymentMode,
        setPaymentMode,
        placeOrder,
        translation,
        paymentLoader,
    } = props;
    const serviceType=localStorage.getItem(SERVICETYPE);
    
    return (
        <div className='flex flex-1 flex-col'>
            <div className='flex flex-col justify-center items-center w-full'>
                <div
                    className={
                        'flex flex-col bg-white rounded-3xl shadow  p-4 justify-center items-center w-40 border-2 border-green-600'
                    }
                >
                    <img
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'contain',
                        }}
                        alt='pay'
                        src={Patment}
                    />
                    <div className='text-xs font-bold text-center mt-4'>
                        {translation['choose_payment']}
                    </div>
                </div>

                <div className='flex flex-row my-4 w-full gap-x-1 justify-center items-center'>
                    <Radio.Group
                        onChange={(e) => setPaymentMode(e.target.value)}
                        value={paymentMode}
                    >
                        <Radio value='CASH'>{serviceType==='delivery' ? translation['by_cash'] : translation['by_cash_pickup']}</Radio>
                        <Radio value='EDC'>
                            { serviceType==='delivery' ? translation['by_edc_machine'] : translation['by_edc_machine_pickup'] }</Radio>
                    </Radio.Group>
                </div>
            </div>
            <Button
                style={{ backgroundColor: themeColors.primaryColor }}
                className='flex-row text-center rounded-full shadow-lg h-12 justify-center items-center gap-x-2 mt-3'
                onClick={placeOrder}
                disabled={paymentLoader}
            >
                {paymentLoader ? (
                    <LoadingOutlined />
                ) : (
                    <div className='text-white text-md text-center'>
                        {translation['place_Order']}
                    </div>
                )}
            </Button>
            {paymentLoader && (
                <div className='text-gray-300 text-xs text-center'>
                    {translation['do_not_press_back_msg']}
                </div>
            )}
        </div>
    );
};

export default PaymentComponent;
