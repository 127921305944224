import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const MenuCard = (props) => {
    const { id, name, image, description, view_only, ...rest } = props;
    const navigate = useNavigate();

    const menuCLickHandler = (id, view_only) => {
        localStorage.setItem('menuID', JSON.stringify(id));
        localStorage.setItem('isViewOnly', Boolean(view_only));
        navigate(`/ordermenu?menuid=${id}`);
    };
    return (
        <div
            className='mb-6 bg-white rounded-3xl shadow-lg'
            onClick={() => menuCLickHandler(id, view_only)}
        >
            {image && (
                <img
                    className='rounded-t-3xl h-72 w-full object-cover'
                    src={`https://master-vserve.s3.ap-south-1.amazonaws.com/menus/${image}`}
                    alt='menu-img'
                />
            )}
            <div className='p-5 flex flex-row justify-between'>
                <div className='text-sm font-medium'>{name}</div>
                <ArrowRightOutlined />
            </div>
        </div>
    );
};

export default MenuCard;
