import { Button, Empty, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-col justify-center items-center flex-1 min-h-screen'>
            <Result
                status='500'
                title='Page Not Found'
                subTitle='Seems Something went wrong! Please scran the QR again or open the link again'
                // extra={
                //     <Button
                //         onClick={() => navigate('/', { replace: true })}
                //     >
                //         Back to Home
                //     </Button>
                // }
            />
        </div>
    );
};

export default PageNotFound;
