import React, { useState } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { themeColors } from '../theme';
import { CheckCircleTwoTone, GlobalOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';

const Language = () => {
    const [languageModal, setLanguageModal] = useState(false);

    const {
        langList,
        changeLanguage,
        lang = 'en',
    } = useLanguage();

    // bottom sheeet
    const changeLanguageHandler = (lang) => {
        changeLanguage(lang);
        setLanguageModal(false);
    };
    return (
        <>
            <div
                className='flex justify-center items-center py-1 px-1 rounded-full flex-row w-9 h-9'
                onClick={() => setLanguageModal(true)}
            >
                <div className='font-medium text-black text-sm'>
                    <GlobalOutlined style={{fontSize:'30px', color: 'white'}}/>
                </div>
            </div>
            <Drawer
                title='Change Language'
                placement={'bottom'}
                closable={false}
                onClose={() => setLanguageModal(false)}
                open={languageModal}
            >
                {langList.map((item, index) => (
                    <div
                        key={item.id}
                        onClick={() => changeLanguageHandler(item.lang_code)}
                        className={
                            'flex flex-row justify-between items-center p-2 px-4 rounded-xl mb-3 h-12 bg-slate-200' +
                            (index === langList.length ? ' mb-6 ' : ' ')
                        }
                        style={{
                            backgroundColor:
                                lang == item.lang_code
                                    ? themeColors.bgColor(0.5)
                                    : '',
                        }}
                    >
                        <div className=' text-black text-sm'>
                            {item.lang_name}
                        </div>
                        {item.lang_code === lang && (
                            <CheckCircleTwoTone twoToneColor='green' />
                        )}
                    </div>
                ))}
            </Drawer>
        </>
    );
};

export default Language;

// {langList.map((item, index) => (
//     <div
//         key={item.id}
//         onClick={() => changeLanguageHandler(item.lang_code)}
//         className={
//             'flex flex-row justify-start items-center p-2 px-4 rounded-xl mb-3 bg-slate-200 h-10 ' +
//             (index === langList.length ? ' mb-6 ' : ' ')
//         }
//         style={{
//             backgroundColor:
//                 lang == item.lang_code
//                     ? themeColors.bgColor(0.5)
//                     : '',
//         }}
//     >
//         {/* <div className='w-6 h-6 rounded-full justify-center items-center mr-2'>
//             <div className='flex font-medium text-black text-sm justify-center items-center'>
//                 {FLAG_LIST[item.lang_code.toUpperCase()]}
//             </div>
//         </div> */}
//         <div className=' text-black text-xs'>
//             {item.lang_code.toUpperCase()} - {item.lang_name}
//         </div>
//     </div>
// ))}
