import React from 'react';
import { themeColors } from '../../theme';

import {
    EditOutlined,
    EyeFilled,
    EyeInvisibleOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { Input, Select } from 'antd';
import { NATIONALITY } from '../../services/Typecode';

const Register = (props) => {
    const {
        userData,
        inputChangeHandler,
        loading,
        registerBtn,
        pencilEditClickHandler,
        hidePassword,
        setHidePassword,
        translation,
    } = props;
    return (
        <div className='flex flex-col w-full'>
            <div className='mb-4 flex justify-center items-center flex-row gap-x-2'>
                <div className='text-sm '>
                    {translation['register_with_us']}
                </div>
                <div className='border rounded-full flex flex-row justify-center items-center px-2 py-1 border-gray-200 gap-x-2'>
                    <div className='mr-3'>
                        <div>{userData.mobile}</div>
                    </div>
                    <div onClick={() => pencilEditClickHandler()}>
                        <div className='flex border border-gray-400 p-1 rounded-full'>
                            <EditOutlined />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-row mb-2 w-full justify-between'>
                <Select
                    showSearch
                    style={{ width: '100%',borderRadius:'0.75rem',height:'46px' }}
                    placeholder={'Select your nationality * '}
                    className='input-wrapper'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={(value) => inputChangeHandler('nationality', value)}
                    options={NATIONALITY}
                />
                {/* <select
                    style={{ width: '100%' }}
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    onChange={(e) =>
                        inputChangeHandler('nationality', e.target.value)
                    }
                    placeholder={'Select your nationality * '}
                    value={userData?.nationality}
                >
                    <option value={''} selected disabled>
                        -- Select Nationality --
                    </option>
                    {NATIONALITY.map((item) => (
                        <option
                            value={item.nationality}
                            key={item.alpha_2_code}
                        >
                            {item.nationality}
                        </option>
                    ))}
                </select> */}
                {/* <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder='Enter last name *'
                    
                    value={userData.last_name}
                /> */}
            </div>
            <div className='flex flex-row mb-2 w-full justify-between'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['enter_first_name']}
                    onChange={(e) =>
                        inputChangeHandler('first_name', e.target.value)
                    }
                    value={userData.first_name}
                    style={{ width: '49%' }}
                />
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['enter_last_name']}
                    onChange={(e) =>
                        inputChangeHandler('last_name', e.target.value)
                    }
                    value={userData.last_name}
                    style={{ width: '49%' }}
                />
            </div>
            <div className='flex mb-2 gap-x-2 w-full'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['enter_email_address']}
                    onChange={(e) =>
                        inputChangeHandler('email', e.target.value)
                    }
                    value={userData.email}
                />
            </div>
            <div className='flex mb-2 gap-x-0 relative w-full'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3 w-full'
                    placeholder={translation['enter_password']}
                    onChange={(e) =>
                        inputChangeHandler('password', e.target.value)
                    }
                    value={userData.password}
                    type={hidePassword ? 'text' : 'password'}
                />
                <div
                    className='text-xs mt-3.5 absolute right-4 top-0 bottom-0'
                    style={{
                        color: themeColors.primaryColor,
                    }}
                    onClick={() => setHidePassword((prevState) => !prevState)}
                >
                    {hidePassword ? <EyeInvisibleOutlined /> : <EyeFilled />}
                </div>
                <div className='text-xs text-gray-500'>
                    {translation['password_validation']}
                </div>
            </div>
            <div className='flex mb-2 gap-x-0 relative w-full'>
                <Input
                    className='h-12 border rounded-xl border-gray-300 p-3'
                    placeholder={translation['enter_confirm_password']}
                    onChange={(e) =>
                        inputChangeHandler(
                            'password_confirmation',
                            e.target.value
                        )
                    }
                    value={userData.password_confirmation}
                    type={hidePassword ? 'text' : 'password'}
                />
                <div
                    className='text-xs mt-3.5 absolute right-4 top-0 bottom-0'
                    style={{
                        color: themeColors.primaryColor,
                    }}
                    onClick={() => setHidePassword((prevState) => !prevState)}
                >
                    {hidePassword ? <EyeInvisibleOutlined /> : <EyeFilled />}
                </div>
            </div>

            <div className='flex flex-row mt-2 items-center justify-center'>
                <div
                    className='flex-row bg-gray-50 p-3 px-10 rounded-full shadow w-36 h-11 justify-center items-center'
                    style={{
                        backgroundColor: themeColors.primaryColor,
                    }}
                    onClick={registerBtn}
                >
                    {loading ? (
                        <LoadingOutlined />
                    ) : (
                        <div className='text-sm font-medium text-white'>
                            {translation['register']}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Register;
